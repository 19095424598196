
<!DOCTYPE html>
<html>

<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css">
</head>

<body>



<div fxFlex="95"fxLayout="column" style="margin: 5px;font-family: Roboto, sans-serif!important;font-size:10px !important;">

  <button mat-button (click)="onSelect_Create_User()"  mat-flat-button class="mat-button-register-user">

    <mat-icon inline=true>add</mat-icon>
    Crear usuario
  </button>


<table mat-table #dataTable  [dataSource]="dataSource" class="mat-elevation-z0">


  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Nombre </th>
    <td mat-cell *matCellDef="let user"> {{user.name}} </td>
  </ng-container>

  <ng-container matColumnDef="firstname">
    <th mat-header-cell *matHeaderCellDef> Apellido </th>
    <td mat-cell *matCellDef="let user"> {{user.last_name}} </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Correo </th>
    <td mat-cell *matCellDef="let user"> {{user.email}} </td>
  </ng-container>

  <ng-container matColumnDef="cargo">
    <th mat-header-cell *matHeaderCellDef> Cargo </th>
    <td mat-cell *matCellDef="let user"> {{user.cargo}} </td>
  </ng-container>
  <ng-container matColumnDef="company">
    <th mat-header-cell *matHeaderCellDef> Compañia </th>
    <td mat-cell *matCellDef="let user"> {{user.company}} </td>
  </ng-container>
  <ng-container matColumnDef="permission">
    <th mat-header-cell *matHeaderCellDef> Permiso </th>
    <td mat-cell *matCellDef="let user"> {{user.permission}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>  </th>
    <mat-cell *matCellDef="let user" >
      <button mat-button (click)="onSelectUser(user)">
        <mat-icon class = "svg" svgIcon="pencil-outline"></mat-icon>
        Editar
      </button>

      <button mat-button  (click)="onDelete(user)">
        <mat-icon class = "svg" svgIcon="delete-outline"></mat-icon>
        Borrar
      </button>
    </mat-cell>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;">

  </tr>
</table>


  </div>




<!-- Placed at the end of the document so the pages load faster -->
<script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
<script src="//maps.googleapis.com/maps/api/js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/js/bootstrap.min.js"></script>


</body>

</html>
