// tslint:disable-next-line:class-name
export class elementoInventario {

  constructor(nombre: string) {
     this.ultimaActualizacion = nombre;
  }
  _id: number;
  nombre: string;
  cantidad: string;
  ultimaActualizacion: string;
}
