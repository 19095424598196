import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Subject} from 'rxjs';
import {environment} from '../../environments/environment.prod';
import {of} from 'rxjs';
import {User} from '../_models/User';
import {elementoInventario} from '../inventario/models/elementoInventario';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {
    this.currentUserObservable = new Subject<any>();
  }
  private currentUser;
  private currentUserObservable: Subject<any>;
  private messageSource = new BehaviorSubject<User>(new User(''));
  currentMessage = this.messageSource.asObservable();
  private messageSourceStock = new BehaviorSubject<elementoInventario>(new elementoInventario(''));


  getUserByEmail(email) {
    return this.http.get(environment.userApiServer + environment.userApi.getUserByEmail + email)
      .subscribe(data => data);
  }

  setCurrentUser(user) {
    this.currentUser = user;
    this.currentUserObservable.next(this.currentUser);
  }

  getCurrentUser() {
    if (!this.currentUser) {
      const strCurrentUser: string = localStorage.getItem('currentUser');
      if (strCurrentUser) {
        this.currentUser = JSON.parse(strCurrentUser);
      }
    }
    // this.currentUserObservable;
    return of(this.currentUser);
  }

  hasPermission(permission: string | string[]): boolean {
    if (localStorage.getItem('currentUser')) {
      // console.log(JSON.parse(localStorage.getItem('currentUser')));
      const userPermissions = JSON.parse(localStorage.getItem('currentUser')).permissions;
      // console.log(permission)
      //  console.log(userPermissions)
      // NOTE(dcampos): si no esta en producción todo mundo tiene acceso a funciones de administración
      if (!environment.production) {
        if (userPermissions.indexOf('admin') > -1) {
          return true;
        }
      }

      if (typeof permission === 'string') {
        for (const p of userPermissions) {

          //  console.log(permission)
          //  console.log(p)

          if (permission === p) {

            //  console.log("aqui2")
            return true;
          }
        }
      } else {
        for (const p1 of userPermissions) {
          for (const p2 of permission) {
            //    console.log(p1)
            //    console.log(p2)

            if (p1 === p2) {
              //   console.log("aqui")
              return true;
            }
          }
        }
      }
    }
    return false;
  }
  User_Edit(message: User) {
    this.messageSource.next(message);
  }
  get_UserEdit() {
    return this.messageSource;
  }
  clear_User_Edit() {

    this.messageSource = new BehaviorSubject<User>(new User(''));
  }

  Stock_Edit(message: elementoInventario) {
    this.messageSourceStock.next(message);
  }
   get_StockEdit() {
    return this.messageSourceStock;
  }
  clear_Stock_Edit() {

    this.messageSourceStock = new BehaviorSubject<elementoInventario>(new elementoInventario(''));
  }


}
