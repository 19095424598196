import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import {
  ComponentPortal,
  ComponentType,
  TemplatePortal,
} from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit, Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {TooltipComponent} from '../tooltip.component';
import { infoMachine } from 'src/app/_models/infoMachine';
import {Router} from '@angular/router';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[customTooltip]',
})
export class CustomTooltipDirective implements OnInit {
  /** Contenido que se va a renderizar dentro del tooltip */
  @Input('customTooltip') tooltipContent: TemplateRef<TooltipComponent> | ComponentType<any>;
  @Input('info')  info: infoMachine;

  /** Overlay que simula ser un tooltip */
  private overlayRef: OverlayRef;

  constructor(
    public overlay: Overlay,
    public overlayPositionBuilder: OverlayPositionBuilder,
    public elementRef: ElementRef,
    public viewContainerRef: ViewContainerRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Si se recibe el contenido a mostrar

    if (this.tooltipContent) {
      // Se crea la configuración de posicionamiento para el tooltip
      const position = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetX: 0,
            offsetY: 8,
          },
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetX: 0,
            offsetY: -8,
          },
        ]);

      // Se crea el overlay y se guarda su referencia
      this.overlayRef = this.overlay.create({
        // Configuración para la posición del overlay
        positionStrategy: position,
        // Comportamiento del overlay cuando se haga scroll y se esté mostrando
        scrollStrategy: this.overlay.scrollStrategies.close(),
        // Clase para darle estilo al overlay
        panelClass: 'custom-tooltip',
      });

      (this.tooltipContent as any).hide = () => {
        this._hide();
      };
    }
    // Se muestra un error si la directiva no recibe contenido para mostrar
    else {
      console.error(
        '[ERROR] La directiva tiene que recibir el contenido a mostrar...'
      );
    }
  }

  @HostListener('mouseenter')
  public _show(): void {
    // Si existe overlay se enlaza con el contenido
    if (this.overlayRef) {
      let containerPortal: TemplatePortal<TooltipComponent> | ComponentPortal<TooltipComponent>;

      // Creamos un TemplatePortal si lo que recibió la directiva era un Template
      if (this.tooltipContent instanceof TemplateRef) {
        containerPortal = new TemplatePortal(
          this.tooltipContent,
          this.viewContainerRef
        );

      }
      // En caso contrario creamos un ComponentPortal
      else {
        containerPortal = new ComponentPortal(
          this.tooltipContent,
          this.viewContainerRef
        );
      }

      // Enlazamos el portal con el overlay creado al iniciar la directiva
      const tooltipRef: ComponentRef< TooltipComponent> =   this.overlayRef.attach(containerPortal);
      tooltipRef.instance.info = this.info;
     // tooltipRef.instance.setData(this.info);

    }
  }


  @HostListener('mouseleave') onMouseLeave(): void {

  }

  @HostListener('mouseout')
  public _hide(): void {
    // Si existe un overlay se desenlaza del contenido
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
  // tslint:disable-next-line:typedef
  public closeToolTip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  // tslint:disable-next-line:typedef use-lifecycle-interface
  ngOnDestroy() {
    this.closeToolTip();
  }

  @HostListener('click', ['$event'])
  onClick(e) {
    //console.log(this.info);
    //console.log("onclick prueba");
    if (this.overlayRef) {
      this.overlayRef.detach();
    }

    this.router.navigate(['/maquina']);
  }
}
