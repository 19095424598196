import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MachineComponent} from './machine/machine.component';
import {MachinesComponent} from './machines/machines.component';
import {LoginComponent} from './login/login.component';
import {RegisterUserComponent} from './register-user/register-user.component';
import {ListUsersComponent} from './list-users/list-users.component';
import {ReportesComponent} from './reportes/reportes.component';
import {InventarioComponent} from './inventario/inventario.component';
import {ReporteComponent} from './reporte/reporte.component';
import {InventarioAgregarComponent} from './inventario-agregar/inventario-agregar.component';
import {VistaPrincipalComponent} from './vista-principal/vista-principal.component';
import {AuthGuard} from './_helpers/auth.guard';
import {ReporteSupervisorComponent} from './reporte-supervisor/reporte-supervisor.component';
import {NgxPermissionsGuard} from 'ngx-permissions';
const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] , data: { permissions: ['ADMIN']
    },
    children: [
      { path: 'inicio', component: VistaPrincipalComponent , pathMatch: 'full',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'ADMIN',
            redirectTo: {
              OPERADOR: 'reportes',
              default: 'reportes'
            }
          }
        }
      },
      { path: 'maquina', component: MachineComponent , pathMatch: 'full'},
      { path: 'maquinas', component: MachinesComponent , pathMatch: 'full'},
      { path: 'registrar', component: RegisterUserComponent, pathMatch: 'full',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'ADMIN',
            redirectTo: {
              OPERADOR: 'reportes',
              default: 'reportes'
            }
          }
        }
      },
      {path:  'usuarios', component: ListUsersComponent, pathMatch: 'full',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'ADMIN',
            redirectTo: {
              OPERADOR: 'reportes',
              default: 'reportes'
            }
          }
        }
      },
      {path:  'reportes', component: ReportesComponent, pathMatch: 'full'},
      {path:  'reporte/:id', component: ReporteComponent, pathMatch: 'full'},
      {path:  'reporte-supervidor/:id', component: ReporteSupervisorComponent, pathMatch: 'full'},
      {path:  'inventario', component: InventarioComponent, pathMatch: 'full'},
      {path:  'agregar-inventario', component: InventarioAgregarComponent, pathMatch: 'full'},
      { path: '', redirectTo: '/inicio', pathMatch: 'full' }
    ]},
  { path: 'login', component: LoginComponent , pathMatch: 'full'},
  { path: '**', redirectTo: '' },
];

export const appRoutingModule = RouterModule.forRoot(routes);
