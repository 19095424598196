import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AgmCoreModule } from '@agm/core';
import { HomeComponent } from './home/home.component';
import {appRoutingModule} from './app-routing.module';
import {MenuMobileComponent} from './_bottomSheet/menu-mobile/menu-mobile.component';
import {ConfirmDialogComponent} from './_dialogs/confirm-dialog/confirm-dialog.component';
import {ImgDialogComponent} from './_dialogs/img-dialog/img-dialog.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {AsyncPipe, CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {MatTableModule} from '@angular/material/table';
import {NgxPermissionsModule} from 'ngx-permissions';
import {BREAKPOINTS, FlexLayoutModule} from '@angular/flex-layout';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {SlimLoadingBarModule} from 'ng2-al-slim-bar';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SidebarModule} from 'ng-sidebar';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import {SidenavService} from './_services/sidenav.service';
import {fakeBackendProvider} from './_helpers/fake-backend';
import { MachineComponent } from './machine/machine.component';
import { MachinesComponent } from './machines/machines.component';
import { NgxSvgModule } from 'ngx-svg';
import { TooltipComponent } from './_tooltip/tooltip.component';
import { CustomTooltipDirective } from './_tooltip/_directives/custom-tooltip.directive';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatDividerModule} from '@angular/material/divider';
import {TooltipDirective} from './tooltip.directive';
import { LoginComponent } from './login/login.component';
import {ListUsersComponent} from './list-users/list-users.component';
import { RegisterUserComponent } from './register-user/register-user.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InventarioComponent } from './inventario/inventario.component';
import { ReportesComponent } from './reportes/reportes.component';
import { ReporteComponent } from './reporte/reporte.component';
import {AgmDialogComponent} from './_dialogs/agm-dialog/agm-dialog.component';
import { InventarioAgregarComponent } from './inventario-agregar/inventario-agregar.component';
import { VistaPrincipalComponent } from './vista-principal/vista-principal.component';
import {GoogleChartsModule} from 'angular-google-charts';
const OVERRIDE_BREAKPOINTS = [
  {
    alias: 'xs',
    mediaQuery: 'screen and (min-width: 1px) and (max-width: 599px)',
    overlapping: false
  },
];

const BreakPointsProvider = {
  provide: BREAKPOINTS,
  useValue: OVERRIDE_BREAKPOINTS,
  multi: true
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ConfirmDialogComponent,
    ImgDialogComponent,
    AgmDialogComponent,
    MenuMobileComponent,
    MachineComponent,
    MachinesComponent,
    TooltipComponent,
    CustomTooltipDirective,
    TooltipDirective,
    LoginComponent,
    ListUsersComponent,
    RegisterUserComponent,
    InventarioComponent,
    ReportesComponent,
    ReporteComponent,
    InventarioAgregarComponent,
    VistaPrincipalComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    ImgDialogComponent,
    MenuMobileComponent,
    TooltipComponent
  ],
  imports: [

    appRoutingModule,
    BrowserModule,
    MatToolbarModule,
    MatDialogModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatSnackBarModule,
    MatIconModule,
    MatMenuModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatSliderModule,
    MatCardModule,
    MatChipsModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatDialogModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatSnackBarModule,
    MatIconModule,
    MatMenuModule,
    MatBottomSheetModule,
    FlexLayoutModule.withConfig({}, OVERRIDE_BREAKPOINTS),
    InfiniteScrollModule,
    NgxSpinnerModule,
    MatSelectModule,
    MatSliderModule,
    MatCardModule,
    MatChipsModule,
    SlimLoadingBarModule.forRoot(),
    // Specify your library as an import
    NgxPermissionsModule.forRoot(),
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyBS0GtdnUi19dpVj5-b_Mlkzi2Tdc_cNYM',
        libraries: ['places']

      }
    ),
    CommonModule,
    MatTableModule,
    MatInputModule,
    MatGridListModule,
    MatSortModule,
    SidebarModule.forRoot(),
    MatPaginatorModule,
    DeviceDetectorModule,
    NoopAnimationsModule,
    MatChipsModule,
    OverlayModule,
    MatDividerModule,
    NgxSvgModule,
    MatFormFieldModule,
    GoogleChartsModule
  ],
  providers: [
    BreakPointsProvider,
     AsyncPipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {provide: MatDialogModule, useValue: {hasBackdrop: false}},
    // provider used to create fake backend
    fakeBackendProvider, SidenavService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    // tslint:disable-next-line:max-line-length
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
    // https://unpkg.com/@mdi/angular-material/mdi.svg en caso que no exista arvhivo Or whatever path you placed mdi.svg at
    // https://dev.materialdesignicons.com/getting-started/angular
  }



}
