import { Component, Input, Renderer2, HostListener,  Directive, ElementRef, TemplateRef, ViewContainerRef,  ContentChild, ComponentRef } from '@angular/core';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[tooltipDirective]' })
export class TooltipDirective {
  private tooltipId: string;

  constructor(
      private renderer: Renderer2,
      private elementRef: ElementRef,
      private viewContainerRef: ViewContainerRef) { }

  @Input() parametroPlantilla: TemplateRef<any>;

  // tslint:disable-next-line:ban-types
  @ContentChild( 'tooltipTemplate' ) private tooltipTemplateRef: TemplateRef<Object>;

  @HostListener('mouseenter')  onMouseEnter(): void {
    const view = this.viewContainerRef.createEmbeddedView(this.tooltipTemplateRef);
    view.rootNodes.forEach(node =>
      this.renderer.appendChild(this.elementRef.nativeElement, node));
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
  }
}
