import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {reporte} from '../reporte/models/reporte';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SlimLoadingBarService} from 'ng2-al-slim-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {ManagmentFilesService} from '../_services/managment-files.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatDialog} from '@angular/material/dialog';
import {AuthenticationService} from '../_services/authentication.service';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  clearFilter = false;
  IconclearFilter = true;
  filterValues = {};
  loading = false;
  ELEMENT_DATA: reporte[] = [];
  ELEMENT_DATA_BACKUP: reporte[] = [];
  displayedColumns: string[] = ['_id', 'tipo_reporte', 'operador', 'fecha', 'faena', 'horometro_inicial', 'horometro_final', 'total_horas', 'produccion', 'maquina', 'flexible', 'cadena', 'espada', 'aceite_cadena', 'cantidad_aceite_hora', 'litros_horas', 'observaciones_mecanicas', 'horometro', 'cantidad_combustible', 'actions'];
  dataSource: MatTableDataSource<reporte> = new MatTableDataSource<reporte>();
  filterSelectObj = [];
  nameNip = 'Angular';
  nameFc = 'Angular';


  constructor(private slimLoadingBarService: SlimLoadingBarService,
              private snackBar: MatSnackBar,
              private http: HttpClient,
              private router: Router,
              private authenticationService: AuthenticationService,
              private activatedRouter: ActivatedRoute,
              private dialog: MatDialog, private spinner: NgxSpinnerService,
              private managmentFilesService: ManagmentFilesService,
              private deviceService: DeviceDetectorService) {

    // Object to create Filter for
    this.filterSelectObj = [

      {
        name: 'Operador',
        columnProp: 'nombre_completo',
        options: []
      }, {
        name: 'Faena',
        columnProp: 'nombre_faena',
        options: []
      }, {
        name: 'Máquina',
        columnProp: 'maquina',
        options: []
      }

      , {
        name: 'Tipo reporte',
        columnProp: 'tipo_reporte',
        options: []
      }

    ];
  }

  ngOnInit(): void {
    const currentUser = this.authenticationService.currentUserValue;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;


    this.slimLoadingBarService.start(() => {
     // console.log('Loading complete');
    });
    this.loading = true;

    this.dataSource.sortingDataAccessor = (item, property) => {

      // console.log(property);
      switch (property) {
        case '_id': return item._id;
        case 'operador': return item.nombre_completo.toLowerCase();
        case 'fecha': return new Date(item.fecha);
        case 'maquina': return item.maquina.toLowerCase();
        case 'faena': return item.nombre_faena.toLowerCase();
        default: return item[property];
      }
    };
    this.managmentFilesService.read_get_info_reportes_resumen_info_tabla(currentUser._id).subscribe(
      data => {
        this.ELEMENT_DATA = data.rows;
        this.ELEMENT_DATA_BACKUP = data.rows;
        this.dataSource.data = data.rows;
        // console.log(data.rows[0]);
        this.slimLoadingBarService.complete();
        this.loading = false;
        this.dataSource.filterPredicate = this.createFilter();

        this.filterSelectObj.filter((o) => {
          // console.log(o.columnProp);
          // console.log("o.columnProp");
          o.options = this.getFilterObject(data.rows, o.columnProp);

        });
      });

  }

  // tslint:disable-next-line:typedef
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  public calculateTotal() {
    // tslint:disable-next-line:only-arrow-functions
    const total_sum = this.ELEMENT_DATA.reduce(function(prev, cur) {
      return prev + cur.total_horas;
    }, 0);

    return total_sum;
  }


  public calculatePromediolitrosHora() {
    // tslint:disable-next-line:only-arrow-functions variable-name
    let amount_not_zeros = 0;
    // tslint:disable-next-line:variable-name
    const total_sum = this.ELEMENT_DATA.reduce(function(prev, cur) {
     if (cur.litros_horas === 0){
        amount_not_zeros += 1;
      }
     return prev + cur.litros_horas;
    }, 0);

    return (total_sum / (this.ELEMENT_DATA.length - amount_not_zeros)).toFixed(2)  + '  Promedio';
  }
  public calculateTotalLitrosAceiteCadena(){
    const total_sum = this.ELEMENT_DATA.reduce(function(prev, cur) {
      return prev + cur.aceite_cadena;
    }, 0);
    return total_sum;
  }
  public calculatePromediolitrosAceiteHora() {
    // tslint:disable-next-line:only-arrow-functions variable-name
    let amount_not_zeros = 0;
    // tslint:disable-next-line:variable-name
    const total_sum = this.ELEMENT_DATA.reduce(function(prev, cur) {
      if (cur.cantidad_aceite_hora === 0){
        amount_not_zeros += 1;
      }
      return prev + cur.cantidad_aceite_hora;
    }, 0);

    return (total_sum / (this.ELEMENT_DATA.length - amount_not_zeros)).toFixed(2)  + '  Promedio';
  }
  public calculateTotalCombustible() {
    // tslint:disable-next-line:only-arrow-functions
    const total_sum = this.ELEMENT_DATA.reduce(function(prev, cur) {
      return prev + cur.cantidad_combustible;
    }, 0);
    return total_sum;
  }
  public calculateTotalCadena() {
    // tslint:disable-next-line:only-arrow-functions
    const total_sum_cadena = this.ELEMENT_DATA.reduce(function(prev, cur) {
      return prev + cur.cadena;
    }, 0);
    return total_sum_cadena;
  }

  public calculateTotalFlexible() {
    // tslint:disable-next-line:only-arrow-functions
    const total_sum_flexible = this.ELEMENT_DATA.reduce(function(prev, cur) {
      return prev + cur.flexible;
    }, 0);
    return total_sum_flexible;
  }
  public calculateTotalEspada() {
    // tslint:disable-next-line:only-arrow-functions
    const total_sum_espada = this.ELEMENT_DATA.reduce(function(prev, cur) {
      return prev + cur.espada;
    }, 0);
    return total_sum_espada;
  }
  assertItemType(item: reporte): reporte {
    return item;
  }
  assertItemTypeName(item: reporte): reporte {
    return item;
  }
  // tslint:disable-next-line:typedef
  OpenInspection( path: reporte) {

//console.log(path);

if (path.tipo_reporte === 'Reporte Operador'){
      const url = this.router.serializeUrl(this.router.createUrlTree(['reporte', path._id], { state: { _id: path._id} }));
      window.open(url, '_blank');

    }else if (path.tipo_reporte === 'Reporte Supervisor'){
      const url = this.router.serializeUrl(this.router.createUrlTree(['reporte-supervidor', path._id], { state: { _id: path._id} }));
      window.open(url, '_blank');
    }
  }
  // tslint:disable-next-line:typedef
  HiddenInspectionBtn( path: reporte) {
    return false; // return path._id > 0;
  }

  HiddenOpenBtnStock(path: reporte){
    return path.count_stock <= 0;
  }
  HiddenOpenBtnImage(path: reporte){
    return path.count_fotos <= 0;
  }
  // Reset table filters
  // tslint:disable-next-line:typedef
  resetFilters() {
    this.clearFilter = true;
    // this.filterValues = {};
    this.ELEMENT_DATA =  this.ELEMENT_DATA_BACKUP;
    this.dataSource.data = this.ELEMENT_DATA_BACKUP;


    this.filterSelectObj.forEach((value, key) => {
      value.modelValue = undefined;
    });
    //   this.dataSource.filter = '';
    this.nameNip = ' ';
    this.nameFc = ' ';
    this.snackBar.open('Limpio el Filtro', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
    setTimeout(() => this.clearFilter = false, 3000);
  }

  // tslint:disable-next-line:typedef
  filterChange(filter, event) {
    // let filterValues = {}
    // console.log(filter.columnProp);
    // console.log(event.target.value.trim().toLowerCase());
    // console.log(filter.columnProp);
    this.filterValues[filter.columnProp] = event.target.value.trim().toLowerCase();
   // console.log(this.filterValues);
    const final = [];
    for (const item of this.ELEMENT_DATA_BACKUP) {
      const checkData = this.filterDataFunction(item, JSON.stringify(this.filterValues));
     // console.log(checkData)
      if (checkData) {
        final.push(item);
      }
    }
    //  console.log('final.length');
    //  console.log(final.length);
    if (final.length > 0) {
      // @ts-ignore
    //  console.log("aquii");
      this.ELEMENT_DATA =  final;
      this.dataSource.data = final;
    }else{
      this.ELEMENT_DATA =  final;
      this.dataSource.data = final;
    }
  }
  public filterDataFunction(data: any, filter: string): boolean {
    const searchTerms = JSON.parse(filter);

    let isFilterSet = false;
    for (const col in searchTerms) {
      if (searchTerms[col].toString() !== '') {
        isFilterSet = true;
      } else {
        delete searchTerms[col];
      }
    }

    const nameSearch = () => {
      let found = false;
      let count_filter = 0;

      if (isFilterSet) {

        // tslint:disable-next-line:forin
        for (const col in searchTerms) {
         // console.log(col)
         // console.log(data)
          if (col === 'nombre_completo') {
            if (data.nombre_completo != null) {
              // tslint:disable-next-line:triple-equals
                 // tslint:disable-next-line:triple-equals
              if (data[col].trim().toLowerCase().indexOf(searchTerms[col].trim().toLowerCase()) != -1 && isFilterSet) {
                // console.log(data[col].trim().toLowerCase());
                // console.log(searchTerms[col].trim().toLowerCase());
                // console.log(data[col].trim().toLowerCase().indexOf(searchTerms[col].trim().toLowerCase()));
                count_filter = count_filter + 1;
               // found = true;
              }
            }
          }else if (col === 'nombre_faena') {
            if (data.nombre_faena != null) {
              // tslint:disable-next-line:triple-equals
              if (data[col].toLowerCase().indexOf(searchTerms[col].toLowerCase()) != -1 && isFilterSet) {
                // console.log(data[col].trim().toLowerCase().indexOf(searchTerms[col].trim().toLowerCase()))
                count_filter = count_filter + 1;
                // found = true;
              }
            }

          }else{
            if (!(data[col].toString().toLowerCase().indexOf(searchTerms[col].trim().toLowerCase()) === -1) && isFilterSet) {
              count_filter = count_filter + 1;
              // found = true;
            }
          }
        }
        const size = Object.keys(searchTerms).length;
        // console.log(size)
        if (count_filter === 0){

          found =  false;
        }else{
          if (count_filter === size){
            found = true;
          }else{
            found =  false;
          }
        }
        return found;
      } else {
        return true;
      }
    };
    return nameSearch();
  }
  // Custom filter method fot Angular Material Datatable
  // tslint:disable-next-line:typedef
  createFilter() {
    // tslint:disable-next-line:only-arrow-functions
    const filterFunction = function(data: any, filter: string): boolean {
      const searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }
      // console.log(searchTerms);
      const nameSearch = () => {
        let found = false;
        let count_filter = 0;
        let count_filterSearchTems = 0;
        if (isFilterSet) {
          count_filterSearchTems = searchTerms.length;
          // tslint:disable-next-line:forin
          for (const col in searchTerms) {
            // console.log(col)
            if (col === 'nombre_completo') {
              if (data.nombre_completo != null) {
                // tslint:disable-next-line:triple-equals
              //  console.log(data.nombre_completo.trim().toLowerCase());
              //  console.log(searchTerms[col].trim().toLowerCase());
              //  console.log(data.nombre_completo.trim().toLowerCase().indexOf(searchTerms[col].trim().toLowerCase()))
                // tslint:disable-next-line:triple-equals
                if (data.nombre_completo.trim().toLowerCase().indexOf(searchTerms[col].trim().toLowerCase()) != -1 && isFilterSet) {
                  count_filter = count_filter + 1;
                 // found = true;
                }
              }

            }else if (col === 'nombre_faena') {

              if (data.nombre_faena != null) {
                // tslint:disable-next-line:triple-equals
               // console.log(data.nombre_completo.trim().toLowerCase().indexOf(searchTerms[col].trim().toLowerCase()))
                if (data.nombre_faena.toLowerCase().indexOf(searchTerms[col].toLowerCase()) != -1 && isFilterSet) {
                  count_filter = count_filter + 1;
                 // found = true;
                }
              }
            }else{
              if (!(data[col].toString().toLowerCase().indexOf(searchTerms[col].trim().toLowerCase()) === -1) && isFilterSet) {
                // found = true;
                count_filter = count_filter + 1;
              }
            }
          }
          const size = Object.keys(searchTerms).length;
          if (count_filter === 0){
            found =  false;
          }else{
            if (count_filter === size){
              found = true;
            }else{
              found =  false;
            }
          }
          return found;
        } else {
          return true;
        }
      };
      return nameSearch();
    };
    return filterFunction;
  }
}
