import { Injectable } from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import {environment} from '../../environments/environment.prod';
import {map} from 'rxjs/operators';
import {Login} from '../_models/login';
import {Users_list} from '../_models/Users_list';
import {elementoInventario_Array} from '../inventario/models/elementoInventario_Array';

@Injectable({
  providedIn: 'root'
})
export class ManagmentUsersService {

  constructor(private permissionsService: NgxPermissionsService, private http: HttpClient, private userService: UserService) { }

  get_all_users() {



    return this.http.post<Users_list>(`${environment.apiUrl}/list_users/`, {})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
       // console.log(user.success);

        return user.info_user;
      }));

  }

  get_all_stock() {
    return this.http.get<elementoInventario_Array>(`${environment.apiUrl}/get_stock/`, {})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
       // console.log(user.success);

        return user.rows;
      }));

  }

  delete_user( id_stock ) {

   // console.log('delete_user');
    // const TOKENN = 'cAVv5erer';
    // const  ciphertext = CryptoJS.AES.encrypt(password, TOKENN).toString();


    // tslint:disable-next-line:prefer-const
    let status = 'delete';

    return this.http.post<Login>(`${environment.apiUrl}/edit_stock/`, {id_stock, status})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        //console.log(user);
        if (user.success) {
          // const perm = [user.info_user.permission];
          // this.permissionsService.loadPermissions(perm);


          // && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          //    localStorage.setItem('currentUser', JSON.stringify(user.info_user));


          //  this.currentUserSubject.next(user.info_user);
          return user.info_user;
        }

        return user;
      }));

  }

}
