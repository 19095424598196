<!DOCTYPE html>
<html>

<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css">
</head>

<body>
<div fxFlex="95"fxLayout="column" >


  <h1  class ="m-3">

    Reportes maquinaria forestal</h1>

  <div>
    <h5 style="text-align:left" class ="m-3">Opciones de filtro</h5>
  </div>

  <div fxFlex="95"fxLayout="column" style="margin: 5px;font-family: Roboto, sans-serif!important;font-size:16px !important;">

    <div fxFlex="15"fxLayout="row">
      <div *ngFor="let filter of filterSelectObj">
        <mat-form-field style="margin-left: 25px;width: 300px;">
          <mat-label>Filtrar {{filter.name}}</mat-label>
          <select style="width: 300px;"  matNativeControl name="{{filter.columnProp}}" [(ngModel)]="filter.modelValue"
                  (change)="filterChange(filter,$event)">
            <option value="">-- Seleccionar {{filter.name}} --</option>
            <option [value]="item" *ngFor="let item of filter.options">{{item}}</option>
          </select>

        </mat-form-field>
      </div>
    </div>
    <div>
      <button style="margin-left: 25px;" mat-flat-button color="warn" (click)="resetFilters()">
        Limpiar filtro
        <span *ngIf="clearFilter" class="spinner-border spinner-border-sm mr-1"></span>
        <mat-icon *ngIf="IconclearFilter"  class ="svg" svgIcon="filter-variant-remove" ></mat-icon>

      </button>
    </div>
    <div class=" contain mat-elevation-z0">




      <table mat-table [dataSource]="dataSource" matSort>

        <!-- Position Column -->
        <ng-container   matColumnDef="_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
          <td mat-cell class ="ml-3" *matCellDef="let element"> {{assertItemType(element)._id}} </td>
          <td mat-footer-cell *matFooterCellDef> Total  </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="tipo_reporte" class="">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de reporte </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).tipo_reporte}}</td>
          <td mat-footer-cell *matFooterCellDef>   </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="operador" class="">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Operador </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).nombre_completo}}</td>
          <td mat-footer-cell *matFooterCellDef>   </td>
        </ng-container>


        <!-- Symbol Column -->

        <ng-container matColumnDef="fecha">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> fecha </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).fecha| date :  "MMM d, y h:mm:ss a"}} </td>
          <td mat-footer-cell *matFooterCellDef>   </td>
        </ng-container>


        <!-- Position Column -->
        <ng-container class ="ml-3" matColumnDef="faena">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Faena</th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).nombre_faena}} </td>
          <td mat-footer-cell *matFooterCellDef>   </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="horometro_inicial">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Horómetro inicial </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).horometro_inicial}} </td>
          <td mat-footer-cell *matFooterCellDef>   </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="horometro_final">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Horómetro final </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).horometro_final}} </td>
          <td mat-footer-cell *matFooterCellDef>   </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="total_horas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Total horas </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).total_horas}} </td>

          <td mat-footer-cell *matFooterCellDef> {{calculateTotal()}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="produccion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Producción </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).produccion}} </td>
          <td mat-footer-cell *matFooterCellDef>   </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="maquina">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Máquina </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).maquina}} </td>
          <td mat-footer-cell *matFooterCellDef>   </td>
        </ng-container>


        <!-- Symbol Column -->
        <ng-container matColumnDef="litros_horas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Litros / Horas </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).litros_horas}} </td>
          <td mat-footer-cell *matFooterCellDef> {{calculatePromediolitrosHora() }}   </td>
        </ng-container>







        <!-- Symbol Column -->
        <ng-container matColumnDef="aceite_cadena">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Aceite cadena </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).aceite_cadena}} </td>
          <td mat-footer-cell *matFooterCellDef> {{calculateTotalLitrosAceiteCadena() }}   </td>
        </ng-container>



        <!-- Symbol Column -->
        <ng-container matColumnDef="cantidad_aceite_hora">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Aceite cadena / Horas </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).cantidad_aceite_hora}} </td>
          <td mat-footer-cell *matFooterCellDef> {{calculatePromediolitrosAceiteHora() }}   </td>
        </ng-container>






        <!-- Symbol Column -->
        <ng-container matColumnDef="flexible">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Flexible </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).flexible}} </td>
          <td mat-footer-cell *matFooterCellDef> {{calculateTotalFlexible() }}   </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="cadena">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cadena </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).cadena}} </td>
          <td mat-footer-cell *matFooterCellDef> {{calculateTotalCadena() }}   </td>
        </ng-container>


        <!-- Symbol Column -->
        <ng-container matColumnDef="espada">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Espada </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).espada}} </td>
          <td mat-footer-cell *matFooterCellDef> {{calculateTotalEspada() }}   </td>
        </ng-container>


        <!-- Symbol Column -->
        <ng-container matColumnDef="observaciones_mecanicas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Observaciones mecánicas </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).observaciones_mecanicas}} </td>
          <td mat-footer-cell *matFooterCellDef>   </td>
        </ng-container>
        <!-- Symbol Column -->
        <!-- Symbol Column -->
        <ng-container matColumnDef="horometro">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Horómetro </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).horometro}} </td>
          <td mat-footer-cell *matFooterCellDef>   </td>
        </ng-container>
        <!-- Symbol Column -->


        <!-- Symbol Column -->
        <ng-container matColumnDef="cantidad_combustible">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad combustible </th>
          <td mat-cell *matCellDef="let element"> {{assertItemType(element).cantidad_combustible}} </td>
          <td mat-footer-cell *matFooterCellDef> {{calculateTotalCombustible()}} </td>
        </ng-container>


        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef ></th>
          <mat-cell *matCellDef="let user" >

            <button mat-button  >
              <mat-icon svgIcon="tools" [hidden]="HiddenOpenBtnStock(user)"></mat-icon>
            </button>
            <button mat-button  >
              <mat-icon svgIcon="camera-outline" [hidden]="HiddenOpenBtnImage(user)"></mat-icon>
            </button>
            <button mat-button (click)="OpenInspection(user)">
              <mat-icon svgIcon="open-in-new" [hidden]="false" ></mat-icon>
            </button>


          </mat-cell>
          <td mat-footer-cell *matFooterCellDef>  </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[30, 60, 90,120, 150,180,210, dataSource.data.length]" showFirstLastButtons></mat-paginator>
    </div>

  </div>




</div>

<!-- Placed at the end of the document so the pages load faster -->
<script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
<script src="//maps.googleapis.com/maps/api/js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/js/bootstrap.min.js"></script>
</body>
</html>

