import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {User} from '../_models/User';
import {UserService} from '../_services/user.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {SlimLoadingBarService} from 'ng2-al-slim-bar';
import {ManagmentUsersService} from '../_services/managment-users.service';
import {ConfirmDialogComponent} from '../_dialogs/confirm-dialog/confirm-dialog.component';
import {elementoInventario} from './models/elementoInventario';

@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.css']
})
export class InventarioComponent implements OnInit {

  @ViewChild('dataTable') dataTable: MatTable<elementoInventario>;
  dataSource: MatTableDataSource<elementoInventario> ;
  displayedColumns: string[] = ['name', 'mount', 'actions'];
  // tslint:disable-next-line:max-line-length
  constructor(private userService: UserService, private router: Router, private dialog: MatDialog, private slimLoadingBarService: SlimLoadingBarService, private  managmentUsersService: ManagmentUsersService) { }
  ELEMENT_DATA: elementoInventario[] = [];

  ngOnInit(): void {

    this.dataSource = new MatTableDataSource<elementoInventario>(this.ELEMENT_DATA);
     this.loadData();

  }

  public loadData() {

    this.slimLoadingBarService.start(() => {
     // console.log('Loading complete');
    });

  this.managmentUsersService.get_all_stock().subscribe(
      (data) => {
        this.ELEMENT_DATA = data;
        this.dataSource.data = this.ELEMENT_DATA;
        this.slimLoadingBarService.complete();
      },
      (error) => { console.log('error', error); },
      () => { console.log('Finally'); }
    );
  }
  onSelectStock( path: elementoInventario) {
    this.userService.Stock_Edit(path);
    this.router.navigate(['agregar-inventario'], { state: { register: 'UPDATE' } });
    //  window.open( 'https://drive.google.com/file/d/' + path.id + '/view');
  }
  onSelect_Create_Stock() {
    this.userService.clear_Stock_Edit() ;
    this.router.navigate(['agregar-inventario'], { state: { register: 'REGISTER' }});
  }
  onDelete( path: elementoInventario) {
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmar',
        body: '¿Está seguro de eliminar a ' + path.nombre + ' ?',
        confirm: 'Borrar',
        confirmClass: 'danger-button',
        cancel: 'Cancelar'
      }
    });
    confirmRef.afterClosed().subscribe(result => {
      if (result) {
        this.managmentUsersService.delete_user(path._id).subscribe(
          data => {
            this.deleteRowData(path);
            this.router.navigate(['inventario']);
          }
        );
      }
    });
  }
  deleteRowData(rowobj: elementoInventario) {
    const index: number = this.ELEMENT_DATA.findIndex(d => d === rowobj);
    this.ELEMENT_DATA.splice(index, 1);
    this.dataSource = new MatTableDataSource<elementoInventario>(this.ELEMENT_DATA);
  }




}
