<p>machine works!</p>

<mat-chip-list class="chip-list">
  <mat-chip color="primary" selected [customTooltip]="tooltipTemplate">
    Tooltip con template
  </mat-chip>
  <mat-chip color="accent" selected [customTooltip]="tooltipComponent">
    Tooltip con componente
  </mat-chip>
</mat-chip-list>

<ng-template #tooltipTemplate>
  <div>
    <h3>Este contenido está siendo pasado desde un template</h3>
    <p>Lorem ipsum dolor sit amet</p>
    <p>consectetur adipiscing elit</p>
    <p>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
  </div>
</ng-template>


