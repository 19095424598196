// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  clients: true,
  self: true,
  production: false,
  tokenPassword : 'q6GW9JxRQR7CasJ7',
  apiServer: '',
  userApiServer: '',
  photoUrl: '',
  docsUrl: '',
  userApi: {
    getUser: '',
    getUserByEmail: '/user&email='
  },

  // tslint:disable-next-line:max-line-length
  firebase : {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
