<!DOCTYPE html>
<html>

<head>
  <!--<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css">-->
</head>

<body>

<div class="col-md-6 offset-md-3 mt-5">
  <div class="login">

    <div class="login-logo col-md-6 offset-md-3">
      <img src="assets/images/albero_logo_400px.png" alt="Logo CADETECH"  >
    </div>

    <div class="login-form">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group " >
          <!-- <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" required placeholder="email"/>
           <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
             <div *ngIf="f.username.errors.required">Username is required</div>
           </div>-->

          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label style="color: #73ca5b" >Email</mat-label>
            <input matInput type="text" formControlName="username"  placeholder="email" style="color: black">
          </mat-form-field>
        </div>
        <div class="form-group ">
          <!-- <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" required placeholder="contraseña" />
           <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
             <div *ngIf="f.password.errors.required">Password is required</div>
           </div>-->
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label style="color: #73ca5b">Contraseña</mat-label>
            <input matInput type="password" formControlName="password"  placeholder="contraseña" style="color: black">
          </mat-form-field>


        </div>



        <div class="form-group">
          <button mat-raised-button  [disabled]="loading"  style="  background-color: #73ca5b;height: 50px; border-radius: 25px;width: 185px;    color: white;
    font-size: medium;">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Iniciar Sesión
          </button>
        </div>
      </form>
    </div>

  </div>
</div>

<!-- Placed at the end of the document so the pages load faster -->
<script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.6/js/bootstrap.min.js"></script>

</body>

</html>
