<!DOCTYPE html>
<html>

<head>
  <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap" rel="stylesheet">
   <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css">
</head>

<body>
<div class="form-group col">
  <div fxLayout="column" fxFlex="90" fxLayoutAlign="center"  >

    <h3  class ="logo  title m-3" fxFlex="none">{{type }} CUENTA!</h3>


    <div fxLayout="column" fxFlex="100"  >
      <!--FORM FIELDS-->

        <form class="form-group" [formGroup]="loginForm">

          <div fxLayout="column" fxFlex="100" fxLayoutAlign="start"  >

          <div fxLayout="{{flex_reduce_size}}" fxFlex="100" >
                  <div class="col-5">
                      <mat-form-field class="m-1">
                         <mat-label>Nombre</mat-label>
                          <input  matInput  formControlName="fname" class="form-control" placeholder="Primer nombre"  name="fname"  required>
                      </mat-form-field>

                  </div>
                  <div class="col-5">
                        <mat-form-field class="m-1">
                          <mat-label>Apellido</mat-label>
                                <input  matInput  formControlName="lname" class="form-control"placeholder="Apellido" name="lname"  required>
                        </mat-form-field>
                  </div>
          </div>

         <mat-form-field class="m-2">
            <mat-label>Cargo</mat-label>
            <input  matInput formControlName="cargo" class="form-control" placeholder="Cargo" name="cargo"  required>
          </mat-form-field >

          <mat-form-field class="m-2" >
            <mat-label>Email</mat-label>
            <input  matInput  formControlName="email" class="form-control" placeholder="Email" name="email">
          </mat-form-field>




          <div fxLayout="{{flex_reduce_size}}" fxFlex="100">

            <div class="col-5">
                <mat-form-field class="m-1">
                   <mat-label>Contraseña</mat-label>
                    <input  matInput  formControlName="password" class="form-control" placeholder="Password"  name="password">
                </mat-form-field>
            </div>

            <div class="col-5">
                <mat-form-field class="m-1">
                   <mat-label>Repetir contraseña</mat-label>
                   <input  matInput  formControlName="repeatPassword" class="form-control " placeholder="Contraseña"  name="password">
                </mat-form-field>
            </div>

          </div>


            <div fxLayout="row" fxFlex="100">
                <mat-form-field  class="m-2" >
                  <mat-label>Permisos</mat-label>
                  <mat-select [(value)]="selected">
                    <mat-option *ngFor="let brand of brands" [value]="brand.value">
                      {{ brand.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
            </div>


    </div>
    </form>
    </div>


    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center" >
    <button mat-raised-button (click)="register()" style="  background-color: #73ca5b;height: 50px; border-radius: 25px;width: 175px;    color: white;
    font-size: medium;" >
      <span *ngIf="registering" class="spinner-border spinner-border-sm mr-1"></span>
      {{ type }}
    </button>
</div>


   <!-- <div *ngxPermissionsOnly="['GUEST']">
      <div>You can see this text congrats GUEST</div>
    </div>

    <ng-template ngxPermissionsOnly="ADMIN">
      <div>You can see this text congrats ADMIN</div>
    </ng-template>-->


  </div>

</div>








  <!-- Placed at the end of the document so the pages load faster -->
  <script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
  <script src="//maps.googleapis.com/maps/api/js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/js/bootstrap.min.js"></script>

</body>

</html>

