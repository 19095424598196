import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../_services/authentication.service';
import {User} from '../_models/User';
import {BehaviorSubject, Observable} from 'rxjs';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  public user: any = {};
  private returnUrl: string;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  error = '';
  constructor(
  private formBuilder: FormBuilder,
  private route: ActivatedRoute,
  private router: Router,
  private authenticationService: AuthenticationService,
  private snackBar: MatSnackBar
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      username: [''],
      password: ['']
    });

    if (this.authenticationService.currentUserValue === undefined) {

      this.router.navigate(['/login']);
    } else {
      if (this.authenticationService.currentUserValue) {
        this.router.navigate(['/']);
      }
    }
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  // tslint:disable-next-line:typedef
  get f() { return this.loginForm.controls; }
  // tslint:disable-next-line:typedef
  onSubmit(){
    this.submitted = true;
    // reset alerts on submit
    // this.alertService.clear();
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    // tslint:disable-next-line:max-line-length
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // tslint:disable-next-line
    const isValidEmail = regEx.test(this.f.username.value)
    if (isValidEmail) {
      this.loading = true;
      this.authenticationService.login(this.f.username.value, this.f.password.value)
        .pipe(first())
        .subscribe(
          data => {
            // console.log(this.returnUrl);
          //  this.messagingService.requestPermission();
          //  this.messagingService.receiveMessage();
            this.loading = false;
            if (data.success){
               if (data.info_user.permission === 'OPERADOR'){
                  this.router.navigate(['reportes/']);
                }else{
                  this.router.navigate(['/']);
                }
              }else{
                this.snackBar.open('Email  o Contraseña incorrecta', '', {
                  duration: 3000, verticalPosition: 'bottom',

                  panelClass: 'notif-success'
                });
              }



          },
          error => {
            this.error = error;
            this.loading = false;

            this.snackBar.open('Error intentarlo denuevo', '', {
              duration: 3000, verticalPosition: 'bottom',
              panelClass: ['blue-snackbar']
            });
          });
    } else {
      this.snackBar.open('Email incorrecto', '', {
        duration: 3000, verticalPosition: 'bottom',

        panelClass: 'notif-success'
      });
    }
  }
}
