import { Component, OnInit } from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MenuGroup} from '../../_models/menu';
import {Router} from '@angular/router';

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.css']
})
export class MenuMobileComponent implements OnInit {

  constructor(private router: Router,
              // tslint:disable-next-line:variable-name
              private _bottomSheetRef: MatBottomSheetRef<MenuMobileComponent>) { }
  // tslint:disable-next-line:variable-name
  public menuGuestCadetech: MenuGroup[] = [
    {
      groupName: 'Menú',
      permissions: ['albero-machines'],
      children: [
        {
          label: 'Reportes',
          icon: 'file-document-outline',
          link: 'reportes',
          permissions: ['albero-machines'],
        },
        {
          label: 'Inventario',
          icon: 'format-list-checks',
          link: 'inventario',
          permissions: ['albero-machines'],
        },
        { label: 'Lista usuarios',
          icon: 'account-multiple-outline',
          link: 'usuarios',
          permissions: ['albero-machines']
        }
      ],
    },
  ];


  public menuGuestCadetechMobile: MenuGroup[] = [
    {
      groupName: 'Menú',
      permissions: ['albero-machines'],
      children: [

        {
          label: 'Dasboard',
          icon: 'home-outline',
          link: 'inicio',
          permissions: ['albero-machines'],
        },


        {
          label: 'Reportes',
          icon: 'file-document-outline',
          link: 'reportes',
          permissions: ['albero-machines'],
        },
        {
          label: 'Inventario',
          icon: 'format-list-checks',
          link: 'inventario',
          permissions: ['albero-machines'],
        },
        { label: 'Lista usuarios',
          icon: 'account-multiple-outline',
          link: 'usuarios',
          permissions: ['albero-machines']
        }
      ],
    },
  ];


  ngOnInit(): void {
  }
  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
  public onNavigateTo(link: string, event: MouseEvent) {
   // console.log('click_navigation');
   // console.log(link);
    // this.drawer.close();
    this._bottomSheetRef.dismiss();
    event.preventDefault();
    this.router.navigate([link]);
  }


  public closeMenu() {
    this._bottomSheetRef.dismiss();
  }
}
