<!DOCTYPE html>
<html>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css"
    />
  </head>

  <body>
    <ng2-slim-loading-bar [color]="'#73ca5b'"></ng2-slim-loading-bar>
    <mat-toolbar class="custom-toolbar" color="primary">
      <mat-toolbar-row>
        <button mat-icon-button>
          <button mat-button mat-icon-button (click)="onToggleSidenav()">
            <mat-icon class="green-icon" alt="menu">menu</mat-icon>
          </button>
          <ng-template ngxPermissionsOnly="ADMIN">
            <a [routerLink]="'/'">

              <img
                src="assets/images/albero_logo_400px.png"
                alt="cadetech"
                height="35px"
              />
            </a>
          </ng-template>

          <ng-template ngxPermissionsOnly="OPERADOR">
            <a [routerLink]="'reportes/'">

              <img
                src="assets/images/albero_logo_400px.png"
                alt="cadetech"
                height="35px"
              />


            </a>
          </ng-template>
        </button>
        <!--<h1>SimpleCRM</h1>-->
        <span class="menu-spacer"></span>

        <!-- This fills the remaining space of the current row -->
        <span class="filler"></span>

        <span>
          <button mat-button [matMenuTriggerFor]="notification">
            <mat-icon class="green_dark-icon">account_circle</mat-icon>
          </button>
          <mat-menu
            #notification="matMenu"
            [overlapTrigger]="false"
            class="my-full-width-menu">

             <ng-template ngxPermissionsOnly="ADMIN">
                    <button mat-menu-item (click)="editInformation()">
                      <mat-icon svgIcon="pencil-outline"></mat-icon>
                      <span>Editar información</span>
                    </button>

             </ng-template>
            <button mat-menu-item (click)="closeSession()">
              <mat-icon svgIcon="import"></mat-icon>
              <span>Cerrar sesión</span>
            </button>
          </mat-menu>
        </span>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container>
      <mat-sidenav #sidenav [hidden]="isMobileMenu" mode="side" opened="true">
        <mat-list>
          <mat-list>
            <div *ngFor="let section of menuUserSelected">
              <mat-list-item>{{ section.groupName }}</mat-list-item>
              <mat-list>
                <div *ngFor="let subSection of section?.children">
                  <mat-list-item>
                    <mat-icon
                      class="svg"
                      mat-list-icon
                      [svgIcon]="subSection.icon"
                    ></mat-icon>
                    <button
                      mat-line
                      mat-button
                      (click)="onNavigateTo(subSection.link)"
                    >
                      {{ subSection.label }}
                      <span *ngIf="subSection.beta" class="beta-option"
                        >beta</span
                      >
                    </button>
                  </mat-list-item>
                </div>
              </mat-list>
            </div>
          </mat-list>
        </mat-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <div style="height: 100vh;">
          <router-outlet> </router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>

    <!-- Placed at the end of the document so the pages load faster -->
    <script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
    <script src="//maps.googleapis.com/maps/api/js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.6/js/bootstrap.min.js"></script>
  </body>
</html>
