import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Router} from '@angular/router';
import { Location } from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../_services/user.service';
import {AuthenticationService} from '../_services/authentication.service';


@Component({
  selector: 'app-inventario-agregar',
  templateUrl: './inventario-agregar.component.html',
  styleUrls: ['./inventario-agregar.component.css']
})
export class InventarioAgregarComponent implements OnInit {
  loginForm: FormGroup;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private  snackBar: MatSnackBar,

    private authenticationService: AuthenticationService) { }

  // tslint:disable-next-line:variable-name
  flex_reduce_size  = 'row';
  registering = false;
  brands: Brand[] = [
    { value: 'ADMIN', viewValue: 'ADMININSTRADOR' },
    { value: 'USER', viewValue: 'USUARIO' },
    { value: 'GUEST', viewValue: 'VISITA' },
    { value: 'EFE', viewValue: 'EFE' }
  ];
  selected = 'USER';
  type = 'REGISTRAR' ;
  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(min-width: 720px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
         // console.log('Viewport is 720px or over!');
          this.flex_reduce_size  = 'row';
        } else {
         // console.log('Viewport is smaller than 720px!');
          this.flex_reduce_size  = 'column';
        }
      });
    // tslint:disable-next-line:triple-equals



    if (this.userService.get_StockEdit().getValue().ultimaActualizacion !== '') {
      this.type = 'ACTUALIZAR' ;


      this.loginForm = this.formBuilder.group({
        fname: [this.userService.get_StockEdit().getValue().nombre, Validators.required],
        lname: [this.userService.get_StockEdit().getValue().cantidad, Validators.required],

      });



    } else {
      this.type = 'REGISTRAR' ;

      this.loginForm = this.formBuilder.group({
        fname: ['', Validators.required],
        lname: ['', Validators.required],
      });
    }
  }

  get f() { return this.loginForm.controls; }

  register() {
         this.registering = true;
    // tslint:disable-next-line:prefer-const
         var status =  (this.type === 'ACTUALIZAR') ? 'update' :  'add';
         this.authenticationService.Edit(this.userService.get_StockEdit().value._id, this.f.fname.value,  this.f.lname.value, status)
          .subscribe(
            data => {
              if (data.success) {
                // tslint:disable-next-line:prefer-const
                var description =  (this.type === 'ACTUALIZAR') ? 'Se Actualizo ' :  'NUEVO usuario';
                this.snackBar.open(description, '', {
                  duration: 3000, verticalPosition: 'bottom',
                  panelClass: ['blue-snackbar']
                });
                this.location.back();
                this.router.navigate(['/inventario']);
              } else if (data.status === 'duplicate') {

                this.snackBar.open('Existe usuario', '', {
                  duration: 3000, verticalPosition: 'bottom',
                  panelClass: ['blue-snackbar']
                });
              }
              this.registering = false;
            },
            error => {
              this.registering = false;
              this.snackBar.open('Error Intentar denuevo', '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: ['blue-snackbar']
              });
            });


  }

}


export interface Brand {
  value: string;
  viewValue: string;
}
