import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationService} from '../_services/authentication.service';
import {UserService} from '../_services/user.service';
import { Location } from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import * as CryptoJS from 'crypto-js';
import {environment} from '../../environments/environment.prod';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent implements OnInit {
  loginForm: FormGroup;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private location: Location,
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private  snackBar: MatSnackBar,
    private authenticationService: AuthenticationService) {
  }
  // tslint:disable-next-line:variable-name
  flex_reduce_size  = 'row';
  registering = false;
  brands: Brand[] = [
    { value: 'ADMIN', viewValue: 'ADMINISTRADOR' },
    { value: 'VISIT', viewValue: 'VISITA' },
    { value: 'USER', viewValue: 'OPERADOR' },
    { value: 'MASTER', viewValue: 'CUENTA MAESTRA' }
  ];
  selected = 'USER';
  type = 'REGISTRAR' ;
  ngOnInit(): void {


   // console.log(this.userService.get_UserEdit().getValue().cargo)
    this.breakpointObserver
      .observe(['(min-width: 720px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
         // console.log('Viewport is 720px or over!');
          this.flex_reduce_size  = 'row';
        } else {
         // console.log('Viewport is smaller than 720px!');
          this.flex_reduce_size  = 'column';
        }
      });
    // tslint:disable-next-line:triple-equals
    if (this.userService.get_UserEdit().value.name.length !== 0) {
      this.type = 'ACTUALIZAR' ;
      // tslint:disable-next-line:max-line-length
      const  ciphertext = CryptoJS.AES.decrypt(this.userService.get_UserEdit().getValue().password, environment.tokenPassword).toString(CryptoJS.enc.Utf8);
      if (ciphertext) {
        this.loginForm = this.formBuilder.group({
          fname: [this.userService.get_UserEdit().getValue().name, Validators.required],
          lname: [this.userService.get_UserEdit().getValue().last_name, Validators.required],
          cargo: [this.userService.get_UserEdit().getValue().cargo, Validators.required],
          email: [this.userService.get_UserEdit().getValue().email, Validators.required],
          password: [ciphertext, Validators.required],
          repeatPassword: [ciphertext, Validators.required]
        });
      } else {
        this.loginForm = this.formBuilder.group({
          fname: [this.userService.get_UserEdit().getValue().name, Validators.required],
          lname: [this.userService.get_UserEdit().getValue().last_name, Validators.required],
          cargo: [this.userService.get_UserEdit().getValue().cargo, Validators.required],
          email: [this.userService.get_UserEdit().getValue().email, Validators.required],
          password: [this.userService.get_UserEdit().getValue().password, Validators.required],
          repeatPassword: [this.userService.get_UserEdit().getValue().password, Validators.required]
        });
      }
    } else {
      this.type = 'REGISTRAR' ;

      this.loginForm = this.formBuilder.group({
        fname: ['', Validators.required],
        lname: ['', Validators.required],
        cargo: ['', Validators.required],
        email: ['', Validators.required],
        password: ['', Validators.required],
        repeatPassword: ['', Validators.required]
      });
    }
  }
  // tslint:disable-next-line:typedef
  get f() { return this.loginForm.controls; }

  // tslint:disable-next-line:typedef
  register() {
    // tslint:disable-next-line:max-line-length
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // tslint:disable-next-line
    const isValidEmail = regEx.test(this.f.email.value)

    if (isValidEmail) {
      if (this.f.password.value  === this.f.repeatPassword.value) {
        const company = 'Albero';
        // tslint:disable-next-line:max-line-length
        this.registering = true;
        // tslint:disable-next-line:max-line-length
        this.authenticationService.register( this.f.cargo.value, this.f.fname.value,  this.f.lname.value, this.f.email.value , this.f.password.value, company, this.selected.toString(), this.type)
          .subscribe(
            data => {
              if (data.success) {
                this.snackBar.open('NUEVO usuario', '', {
                  duration: 3000, verticalPosition: 'bottom',
                  panelClass: ['blue-snackbar']
                });
                this.location.back();
                this.router.navigate(['/usuarios']);
              } else if (data.status === 'duplicate') {

                this.snackBar.open('Existe usuario', '', {
                  duration: 3000, verticalPosition: 'bottom',
                  panelClass: ['blue-snackbar']
                });
              }
              this.registering = false;
            },
            error => {
              this.snackBar.open('Error Intentar denuevo', '', {
                duration: 3000, verticalPosition: 'bottom',
                panelClass: ['blue-snackbar']
              });
            });
      } else {
        this.snackBar.open('No coinciden las  contraseñas', '', {
          duration: 3000, verticalPosition: 'bottom',
          panelClass: ['blue-snackbar']
        });
      }
    } else {
      this.snackBar.open('Email no valido', '', {
        duration: 3000, verticalPosition: 'bottom',
        panelClass: ['blue-snackbar']
      });
    }
  }
}
export interface Brand {
  value: string;
  viewValue: string;
}
