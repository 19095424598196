import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-root-agm-dialog',
  templateUrl: './agm-dialog.component.html',
  styleUrls: ['./agm-dialog.component.scss']
})
export class AgmDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) { }
  typemap = 'roadmap';

  // tslint:disable-next-line:typedef
  ngOnInit() {
  }
  // tslint:disable-next-line:typedef
  changeMap() {
    if (this.typemap === 'roadmap') {
      this.typemap = 'hybrid';
    } else if (this.typemap === 'hybrid') {
      this.typemap = 'roadmap';
    }

    this.snackBar.open('Cambio a mapa: ' + this.typemap, '', {
      duration: 3000, verticalPosition: 'top',
      panelClass: ['blue-snackbar']
    });
  }
}
