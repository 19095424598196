import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, first} from 'rxjs/operators';
import {AuthenticationService} from '../_services/authentication.service';
import {NgxPermissionsService} from 'ngx-permissions';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService,
              private permissionsService: NgxPermissionsService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if ([401, 403].indexOf(err.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
       const password =  this.authenticationService.currentUserValue.password;
       const email = this.authenticationService.currentUserValue.email;
       this.authenticationService.login(email, password)
          .pipe(first())
          .subscribe(
            user => {
              if (user) {
                console.log('ErrorInterceptor');
                const perm = [user.info_user.permission];
                this.permissionsService.loadPermissions(perm);
                // && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user.info_user));
                return next.handle(this.injectToken(request));
              } else {
                this.authenticationService.logout();
                location.reload(true);
              }
            },
            // tslint:disable-next-line:no-shadowed-variable
            error => {
              this.authenticationService.logout();
              location.reload(true);
            });
      }
      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }

  injectToken(request: HttpRequest<any>) {
    const currentUser = this.authenticationService.currentUserValue;
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${currentUser.token}`
      }
    });
  }
}
