import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {SlimLoadingBarService} from 'ng2-al-slim-bar';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {ManagmentFilesService} from '../_services/managment-files.service';
import {reporte} from '../reporte/models/reporte';
import {AgmDialogComponent} from '../_dialogs/agm-dialog/agm-dialog.component';
import {imagen} from '../reporte/models/imagen';
import {ImgDialogComponent} from '../_dialogs/img-dialog/img-dialog.component';
import {itemStock} from '../reporte/models/itemStock';

@Component({
  selector: 'app-reporte-supervisor',
  templateUrl: './reporte-supervisor.component.html',
  styleUrls: ['./reporte-supervisor.component.css']
})
export class ReporteSupervisorComponent implements OnInit {
  public _data: reporte;
  public isDSSInspection = false;

  constructor(private dialog: MatDialog,
              private slimLoadingBarService: SlimLoadingBarService,
              private snackBar: MatSnackBar,
              private http: HttpClient,
              private router: Router,
              private activatedRouter: ActivatedRoute,
              private managmentFilesService: ManagmentFilesService) { }

  ngOnInit(): void {
    this.activatedRouter.params.subscribe(params => {

      this.slimLoadingBarService.start(() => {

      });
      this.managmentFilesService.get_info_reporte_supervidor_resumen_info_tabla(params.id).subscribe(
        data => {
          this.slimLoadingBarService.complete();
          if (!data) {

            this.snackBar.open('Inspección sin información', '', {
              duration: 3000, verticalPosition: 'bottom',
              panelClass: ['blue-snackbar']
            });

            this.router.navigate(['inspecciones']);
          }
         // console.log(data.rows[0]);
          this.isDSSInspection = true;
          this._data = data.rows[0];
        });
    });

  }



  // tslint:disable-next-line:typedef
  show_maps_position() {
    // tslint:disable-next-line:variable-name
    const  lat_ = this._data.lat;
    // tslint:disable-next-line:variable-name
    const lng_ = this._data.lon;
   // console.log(lat_)
   // console.log(lng_)

    if(lat_ === 0 && lng_ === 0 ){
      this.snackBar.open('Sin coordenadas', '', {
        duration: 3000, verticalPosition: 'bottom',
        panelClass: ['blue-snackbar']
      });
    }else {


      const confirmRef = this.dialog.open(AgmDialogComponent, {
        data: {
          lat: Number(lat_),
          lng: Number(lng_),
          zoom: 12,
          confirm: 'Borrar',
          confirmClass: 'danger-button',
          cancel: 'Cerrar'
        }
      });

      confirmRef.afterClosed().subscribe(result => {
        if (result) {

        }
      });
    }
  }

  // tslint:disable-next-line:variable-name
  show_image(urlshow: imagen) {

    const confirmRef = this.dialog.open(ImgDialogComponent, {
      data: {
        lng: 26,
        lat: 26,
        url: urlshow.url,
        confirm: 'Descargar',
        confirmClass: 'danger-button',
        cancel: 'Cerrar'
      }
    });

    confirmRef.afterClosed().subscribe(result => {
      if (result) {
        // window.open(`${environment.apiUrl}/download_image_one_inspection/` + id_gdrive + '/download' );
      }
    });
  }



  assertItemType(item: imagen): imagen {
    return item;
  }
  assertItemTypeStock(item: itemStock): itemStock {
    return item;
  }

}
