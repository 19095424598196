<div #tooltip class="tooltip"></div>


<!-- Estaba probando con esta libreria  https://www.npmjs.com/package/ngx-svg  para que de un objeto obtener la información

<svg-container containerId="lineContainer">
  <svg-image

    imageUrl="https://sfxpublic.s3-sa-east-1.amazonaws.com/test_webviewer/0201_000.png">

  </svg-image>

  <svg-image *ngFor="let line of ElementDataMachine"

            [imageUrl]=line.photoLink

            >


  </svg-image>
  <svg-rect [height]="80"

            [width]="80" [x]="1050" [y]="950"

            (click)="onMachineSelected()"
            [customTooltip]="tooltipComponent"
            [info]="{{ElementDataMachine[]}}"
  >

  </svg-rect>
</svg-container>
-->

<!--
<svg-container>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    viewBox="0 0 1920 1080"
  >
    <g id="layer1">
      <image
        href="https://sfxpublic.s3-sa-east-1.amazonaws.com/test_webviewer/0201_000.png"
        width="100%"
        height="100%"
        x="0"
        y="0"
      />

    <ng-container *ngFor="let machine of ElementDataMachine ; let j = index">


       <svg-image
         height =1080
         width="1920"
       imageUrl="{{machine.photoLink}}"
       >
         <animate
           id="animation1"
           attributeName="opacity"
           from="0"
           to="0.5"
           dur="0.1s"
           begin="0s;animation2.end"
         />
         <animate
           id="animation2"
           attributeName="opacity"
           from="0.5"
           to="0"
           dur="1.5s"
           begin="animation1.end"
         />

       </svg-image>
        <image
          href="https://sfxpublic.s3-sa-east-1.amazonaws.com/test_webviewer/0201_Rotopala_rojo.png"
          width="100%"
          height="100%"
          x="0"
          y="0"
        >
          <animate
            id="animation1"
            attributeName="opacity"
            from="0"
            to="0.5"
            dur="0.1s"
            begin="0s;animation2.end"
          />
          <animate
            id="animation2"
            attributeName="opacity"
            from="0.5"
            to="0"
            dur="1.5s"
            begin="animation1.end"
          />
        </image>


        <g layer="interactive-layer">
          <rect
            (click)="onMachineSelected(machine)"
            (myClick)="onClick(machine)"
            [customTooltip]="tooltipComponent"
            [info]="machine"
            width="80"
            height="80"
            style="fill: rgb(0, 0, 255, 0); stroke-width: 0;"
            x="1050"
            y="100"

          />
        </g>
      </ng-container>

      <image
        href="https://i.ibb.co/G9mmxCG/0201-204.png"
        width="100%"
        height="100%"
        x="0"
        y="0"
      >
        <animate
          id="animation1"
          attributeName="opacity"
          from="0"
          to="0.5"
          dur="0.1s"
          begin="0s;animation2.end"
        />
        <animate
          id="animation2"
          attributeName="opacity"
          from="0.5"
          to="0"
          dur="1.5s"
          begin="animation1.end"
        />
      </image>

      <image
        href="https://i.ibb.co/SBJ0HpN/0201-202.png"
        width="100%"
        height="100%"
        x="0"
        y="0"
      >
        <animate
          id="animation1"
          attributeName="opacity"
          from="0"
          to="0.5"
          dur="0.1s"
          begin="0s;animation2.end"
        />
        <animate
          id="animation2"
          attributeName="opacity"
          from="0.5"
          to="0"
          dur="1.5s"
          begin="animation1.end"
        />
      </image>
      <image
        href="https://i.ibb.co/kxsQ4js/0201-205.png"
        width="100%"
        height="100%"
        x="0"
        y="0"
      >
        <animate
          id="animation1"
          attributeName="opacity"
          from="0"
          to="0.5"
          dur="0.1s"
          begin="0s;animation2.end"
        />
        <animate
          id="animation2"
          attributeName="opacity"
          from="0.5"
          to="0"
          dur="1.5s"
          begin="animation1.end"
        />
      </image>

      <image
        href="https://i.ibb.co/pQcGgzS/0201-Spreader.png"
        width="100%"
        height="100%"
        x="0"
        y="0"
      >
        <animate
          id="animation1"
          attributeName="opacity"
          from="0"
          to="0.5"
          dur="0.1s"
          begin="0s;animation2.end"
        />
        <animate
          id="animation2"
          attributeName="opacity"
          from="0.5"
          to="0"
          dur="1.5s"
          begin="animation1.end"
        />
      </image>


      <image
        href="https://i.ibb.co/kxsQ4js/0201-205.png"
        width="100%"
        height="100%"
        x="0"
        y="0"
      >
        <animate
          id="animation1"
          attributeName="opacity"
          from="0"
          to="0.5"
          dur="0.1s"
          begin="0s;animation2.end"
        />
        <animate
          id="animation2"
          attributeName="opacity"
          from="0.5"
          to="0"
          dur="1.5s"
          begin="animation1.end"
        />
      </image>


    </g>

  <g layer="interactive-layer">
      <rect
        (click)="onMachineSelected()"
        [customTooltip]="tooltipComponent"
        [info]="info"
        width="80"
        height="80"
        style="fill: rgb(0, 0, 255, 0); stroke-width: 0;"
        x="1100"
        y="100"
      />
    </g>


    <g layer="interactive-layer">
      <rect
        (click)="onMachineSelected()"
        [customTooltip]="tooltipComponent"
        [info]="info"
        width="80"
        height="80"
        style="fill: rgb(0, 0, 255, 0); stroke-width: 0;"
        x="950"
        y="100"
      />
    </g>


    <g layer="interactive-layer">
      <rect
        (click)="onMachineSelected()"
        [customTooltip]="tooltipComponent"
        [info]="info"
        width="80"
        height="80"
        style="fill: rgb(0, 0, 255, 0); stroke-width: 0;"
        x="900"
        y="160"
      />
    </g>

    <g layer="interactive-layer">
      <rect
        (click)="onMachineSelected()"
        [customTooltip]="tooltipComponent"

        [info]="info2"
        width="100"
        height="100"
        style="fill: rgb(0, 0, 255,0); stroke-width: 0;"
        x="1650"
        y="200"
      />
    </g>



  </svg>

</svg-container>

-->
