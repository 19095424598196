import { Injectable } from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Login} from '../_models/login';
import {environment} from '../../environments/environment.prod';
import {map} from 'rxjs/operators';
import {reporte} from '../reporte/models/reporte';
import {reporte_array} from '../reporte/models/reporte_array';
import {dashboard_reportes} from '../vista-principal/models/dashboard_reportes';
import {dashboard} from '../vista-principal/models/dashboard';
@Injectable({
  providedIn: 'root'
})
export class ManagmentFilesService {
  // tslint:disable-next-line:max-line-length
  constructor(private storage: StorageMap,
              private permissionsService: NgxPermissionsService,
              private http: HttpClient, private userService: UserService, private deviceService: DeviceDetectorService) { }
  deviceInfo = null;

  read_info_area_filter(id: string, area: string) {

    return this.http.post<Login>(`${environment.apiUrl}/get_info_filter/`, {id, area})
      .pipe(map(data => {
        // login successful if there's a jwt token in the response
        // console.log(data);
        return data;
      }));
  }
  read_info_get_dashboard() {

    return this.http.get<dashboard>(`${environment.apiUrl}/get_dashboard/`, {})
      .pipe(map(data => {
        // login successful if there's a jwt token in the response
         console.log(data);
         return data;
      }));
  }
  read_get_info_reportes_resumen_info_tabla(user_id:string) {
    return this.http.post<reporte_array>(`${environment.apiUrl}/get_info_reportes_resumen_info_tabla/`, {user_id})
      .pipe(map(row => {
        // login successful if there's a jwt token in the response
        return row;
      }));

  }

  // tslint:disable-next-line:typedef
  get_info_reporte_resumen_info_tabla(id: number) {

    return this.http.get<reporte_array>(`${environment.apiUrl}/get_info_reporte_resumen_info_tabla/` + id)
      .pipe(map(res => {
        //console.log(res);
        return res;
      }));

  }

  get_info_reporte_supervidor_resumen_info_tabla(id: number) {

    return this.http.get<reporte_array>(`${environment.apiUrl}/get_info_reporte_supervisor_resumen_info_tabla/` + id)
      .pipe(map(res => {
        //console.log(res);
        return res;
      }));

  }

}



