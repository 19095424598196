<!DOCTYPE html>
<html>

<head>
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css">
</head>

<body>
<h1 style="text-align:left" class ="m-3">Dashboard 📈</h1>

<!--
<div>
  <div
    class="container flex-row-container"
    fxLayout="row"
    fxFlexFill
    fxLayoutAlign="space-evenly"
  >


    <mat-card class="container_cardview mat-card-chart flex-row-item ml-2 mt-1">
      <h6>{{title_zona2}} / {{total_doc_zona2_realizados}} de {{total_doc_zona2}}   </h6>
      <google-chart
        [title]="title_zona2"
        [type]="myType"
        [data]="data_zona2"
        [width]="300"
        [height]="200"
        [options]="options2"
        [columnNames]="columnNames"  ></google-chart>
    </mat-card>
    <mat-card class="container_cardview mat-card-chart flex-row-item mt-1">
      <h6>{{title_zona7}} / {{total_doc_zona7_realizados}} de {{total_doc_zona7}}   </h6>
      <google-chart
        [title]="title_zona7"
        [type]="myType"
        [width]="300"
        [height]="200"
        [data]="data_zona7"
        [options]="options7 "
        [columnNames]="columnNames"></google-chart>
    </mat-card >
    <mat-card class="container_cardview mat-card-chart flex-row-item mt-1">
      <h6>{{title_zona8}} / {{total_doc_zona8_realizados}} de {{total_doc_zona8}}   </h6>
      <google-chart
        [title]="title_zona8"
        [type]="myType"
        [data]="data_zona8"
        [width]="300"
        [height]="200"
        [options]="options8 "
        [columnNames]="columnNames" ></google-chart>
    </mat-card>
  </div>
</div>

-->



<div fxLayout="column">

  <div class  ="container_table_view mb-3 ml-2" fxFlex="{{percentflex}}" fxLayout="column">
    <h1 style="text-align:left" class ="m-3">Información Consumo Combustible 🛢️</h1>

    <div fxFlex="15"fxLayout="row">
      <div *ngFor="let filter of filterSelectObj">
        <mat-form-field style="margin-left: 25px;width: 300px;">
          <mat-label>Filtrar {{filter.name}}</mat-label>
          <select style="width: 300px;"  matNativeControl name="{{filter.columnProp}}" [(ngModel)]="filter.modelValue"
                  (change)="filterChangeInfoGeneral(filter,$event)">
            <option value="">-- Seleccionar {{filter.name}} --</option>
            <option [value]="item" *ngFor="let item of filter.options">{{item}}</option>
          </select>
        </mat-form-field>
      </div>
    </div>
    <div>
      <button style="margin-left: 25px;" mat-flat-button color="warn" (click)="resetFilters()">
        Limpiar filtro
        <span *ngIf="clearFilter" class="spinner-border spinner-border-sm mr-1"></span>
        <mat-icon *ngIf="IconclearFilter"  class ="svg" svgIcon="filter-variant-remove" ></mat-icon>

      </button>
    </div>
    <table mat-table #dataTable  [dataSource]="dataSourceInfoGeneral" class="mat-elevation-z0">

      <ng-container *ngFor="let disCol of displayedColumnsInfoGeneral; let colIndex = index" matColumnDef="{{disCol}}">
        <th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
        <td mat-cell *matCellDef="let element"> {{assertItemTypeInfoGeneral(disCol,element)}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsInfoGeneral"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsInfoGeneral;">
      </tr>

    </table>
  </div>


  <div class  ="container_table_view mb-3 ml-2" fxFlex="{{percentflex}}" fxLayout="column">
    <h1 style="text-align:left" class ="m-3">Información Operador 👨</h1>


    <table mat-table #dataTable  [dataSource]="dataSource" class="mat-elevation-z0">

      <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
        <th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
        <td mat-cell *matCellDef="let element"> {{assertItemType(disCol,element)}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>

    </table>
  </div>

  <div class  ="container_table_view mb-3 ml-2" fxFlex="{{percentflex}}" fxLayout="column">
    <h1 style="text-align:left" class ="m-3">Información Máquinas ⚙️</h1>

    <table mat-table #dataTable  [dataSource]="dataSourceMaquinas" class="mat-elevation-z0">

      <ng-container *ngFor="let disCol of displayedColumnsMaquina; let colIndex = index" matColumnDef="{{disCol}}">
        <th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
        <td mat-cell *matCellDef="let element"> {{assertItemTypeMaquina(disCol,element)}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsMaquina"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsMaquina;">
      </tr>
    </table>
  </div>
<div class="container_inspection ml-2"  fxFlex="45" fxLayout="column" >
    <h1 style="text-align:left" class ="m-3">Stock más solicitado 🧰 </h1>
    <button mat-button (click)="onClickCard_Show_Inspections()" >Ver todo Stock</button>

    <table mat-table #dataTableStock  [dataSource]="dataSourceStock" class="mat-elevation-z0" matSort>


      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº </th>
        <td mat-cell *matCellDef="let element"> {{assertItemTypeStock(element)._id}} </td>
      </ng-container>


      <ng-container matColumnDef="ultima_fecha" class="">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Última solicitud </th>
        <td mat-cell *matCellDef="let element"> {{assertItemTypeStock(element).ultima_fecha| date :  "MMM d, h:mm:ss a"}}</td>
      </ng-container>


      <ng-container matColumnDef="count_total">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
        <td mat-cell *matCellDef="let element"> {{assertItemTypeStock(element).count_total}} </td>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE </th>
        <td mat-cell *matCellDef="let element"> {{assertItemTypeStock(element).nombre}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumnsStock"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsStock;"></tr>

    </table>
  </div>
</div>
<!-- Placed at the end of the document so the pages load faster -->
<script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
<script src="//maps.googleapis.com/maps/api/js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/js/bootstrap.min.js"></script>

</body>

</html>
