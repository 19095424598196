export const environment = {
  clients: true,
  self: true,
  production: true,
  tokenPassword : 'q6GW9JxRQR7CasJ7',
  apiServer: '',
  userApiServer: '',
  photoUrl: '',
  docsUrl: '',
  userApi: {
    getUser: '',
    getUserByEmail: '/users/u='
  },
  apiUrl: 'https://api.foralbero.com',
  // tslint:disable-next-line:max-line-length
  firebase : {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};
