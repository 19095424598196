import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {reporte} from '../reporte/models/reporte';
import {Subscription} from 'rxjs';
import {SlimLoadingBarService} from 'ng2-al-slim-bar';
import {StorageMap} from '@ngx-pwa/local-storage';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {ManagmentFilesService} from '../_services/managment-files.service';
import {dashboard_reportes} from './models/dashboard_reportes';
import {dashboard} from './models/dashboard';
import {dashboard_stock} from './models/dashboard_stock';
import {dashboard_maquinas} from './models/dashboard_maquinas';
import {dashboard_info} from './models/dashboard_info';

@Component({
  selector: 'app-vista-principal',
  templateUrl: './vista-principal.component.html',
  styleUrls: ['./vista-principal.component.css']
})
export class VistaPrincipalComponent implements OnInit {
  @ViewChild('dataTable') dataTable: MatTable<dashboard_reportes>;

  @ViewChild('dataTableStock') dataTableStock: MatTable<dashboard_stock>;
  clearFilter = false;
  IconclearFilter = true;
  dataSource: MatTableDataSource<dashboard_reportes> ;
  dataSourceInfoGeneral: MatTableDataSource<dashboard_info> ;
  dataSourceMaquinas: MatTableDataSource<dashboard_maquinas> ;
  dataSourceStock: MatTableDataSource<dashboard_stock> ;
  // tslint:disable-next-line:variable-name
  info_dashAll: dashboard;
  filterSelectObj = [];
  filterInfoGeneralValues = {};
  loading = false;
  ELEMENT_DATA_BACKUP_INFO_GENERAL: dashboard_info[] = [];
  myType = 'PieChart'; // 'PieChart';////https://jsfiddle.net/L5wc8rcp/1/ ejemplo de un grafico de barras
  // tslint:disable-next-line:variable-name
  title_zona2 = 'Faena 2';
  // tslint:disable-next-line:variable-name
  title_zona7 = 'Faena 7';
  // tslint:disable-next-line:variable-name
  title_zona8 = 'Faena 8';
  columnNames = ['Revisión', 'Cantidad'];
  // tslint:disable-next-line:variable-name
  total_doc_zona2 = 0;
  // tslint:disable-next-line:variable-name
  total_doc_zona2_realizados = 0;
  // tslint:disable-next-line:variable-name
  total_doc_zona7 = 0;
  // tslint:disable-next-line:variable-name
  total_doc_zona7_realizados = 0;
  // tslint:disable-next-line:variable-name
  total_doc_zona8 = 0;
  // tslint:disable-next-line:variable-name
  total_doc_zona8_realizados = 0;

  // https://www.color-hex.com/color/e0440e
  options2 = {
    pieSliceText: 'value',
    pieHole: '0.5',
    colors: ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'], is3D: false,
    animation: {
      duration: 3000,
      easing: 'out',
      startup: true
    },
    chartArea: {
      left: '0%',
      top: '0%',
      right: '0%',
      height: '94%',
      width: '70%'
    },
    titleTextStyle: {
      color: '#131212',    // any HTML string color ('red', '#cc00cc')
      fontName: 'Times New Roman', // i.e. 'Times New Roman'
      fontSize: 18, // 12, 18 whatever you want (don't specify px)
      bold: true,    // true or false
      italic: true   // true of false
    }
  };

  /// https://www.color-hex.com/color/346ac3
  options7 = {
    pieSliceText: 'value',
    pieHole: '0.5',
    colors: ['#346ac3', '#7096d5', '#99b4e1', '#c2d2ed', '#adc3e7'], is3D: false,
    animation: {
      duration: 3000,
      easing: 'out',
      startup: true
    },
    chartArea: {
      left: '0%',
      top: '0%',
      right: '0%',
      height: '94%',
      width: '70%'
    }
  };
// https://www.schemecolor.com/the-24-best-green-monochromatic-color-palette.php
  options8 = {
    pieSliceText: 'value',
    pieHole: '0.5',
    colors: ['#358856', '#5aab61', '#62bd69', '#3FC283', '#87e9a9'], is3D: false,
    animation: {
      duration: 3000,
      easing: 'out',
      startup: true
    },
    chartArea: {
      left: '0%',
      top: '0%',
      right: '0%',
      height: '94%',
      width: '70%'
    }
  };

  // tslint:disable-next-line:variable-name
  data_zona2 = [
    ['Turno 0', 45],
    ['Turno A', 26],
    ['Turno B', 12],
    ['Turno C', 8],
    ['Turno D', 6]
  ];
  // tslint:disable-next-line:variable-name
  data_zona7 = [
    ['Turno 0', 45],
    ['Turno A', 26],
    ['Turno B', 12],
    ['Turno C', 8],
    ['Turno D', 6]
  ];
  // tslint:disable-next-line:variable-name
  data_zona8 = [
    ['Turno 0', 45],
    ['Turno A', 26],
    ['Turno B', 12],
    ['Turno C', 8],
    ['Turno D', 6]
  ];

  // tslint:disable-next-line:max-line-length
  //// esto permite generar overlap sobre el mapa  https://stackoverflow.com/questions/10656743/how-to-offset-the-center-point-in-google-maps-api-v3
  ELEMENT_DATA_STOCK: dashboard_stock[] = [];
  ELEMENT_DATA: dashboard_reportes[] = [];
  ELEMENT_DATA_INFO: dashboard_info[] = [];
  ELEMENT_DATA_MAQUINA: dashboard_maquinas[] = [];
  ELEMENT_DATA_INSPECTIONS: reporte[] = [];
  public dss = false;
  percentflex = '50';
  // tslint:disable-next-line:variable-name
  private _subscriptions: Subscription[] = [];
  ELEMENT_DATA_CHARTS_DOCUMENTS: reporte[] = [];
  ELEMENT_DATA_DOCUMENTS: reporte[] = [];
  // tslint:disable-next-line:variable-name
  flex_reduce_size  = 'row';
  displayedColumns: string[] = [
    'Operario',
    'Cantidad de reportes',
    'Rumas/Hr',
    'Total horas reportes',
    'Arrumado Turno A',
    'Arrumado Turno B',
    'Arrumado Sin Turno'
  ];


  displayedColumnsInfoGeneral: string[] = [
    'Faena',
    'Nombre',
    'Apellido',
    'Cargo',
    'Total Horas',
    'Total Combustible',
    'Total Reportes'
  ];


  displayedColumnsMaquina: string[] = [
    'Nº',
    'Nombre',
    'Número reportes',
    'Total consumo(Lt)',
    'Lt/hora',
    'Promedio(Lt)',
    'Aceite cadena/hr'
  ];

  displayedColumnsStock: string[] = [
    '_id',
    'nombre',
    'ultima_fecha',
    'count_total',

  ];



  constructor(  // private breakpointObserver: BreakpointObserver,
    private slimLoadingBarService: SlimLoadingBarService,
    private storage: StorageMap,
    private snackBar: MatSnackBar, private managmentFilesService: ManagmentFilesService,
    private router: Router) {

    // Object to create Filter for
    this.filterSelectObj = [
       {
        name: 'Faena',
        columnProp: 'faena',
        options: []
      }
    ];
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<dashboard_reportes>(this.ELEMENT_DATA);
    this.dataSourceStock = new MatTableDataSource<dashboard_stock>(this.ELEMENT_DATA_STOCK);
    this.dataSourceMaquinas = new MatTableDataSource<dashboard_maquinas>(this.ELEMENT_DATA_MAQUINA);
    this.dataSourceInfoGeneral = new MatTableDataSource<dashboard_info>(this.ELEMENT_DATA_INFO);
    this.storage.delete('list_inspections').subscribe(() => {});
    this.slimLoadingBarService.start(() => {
     // console.log('Loading complete');
    });
    this.managmentFilesService.read_info_get_dashboard()
      .subscribe(
        data => {
          if (this.flex_reduce_size === 'column') {
            this.info_dashAll = data;
            const arr = [];
            this.displayedColumns = [
              'Operario',
              'Cantidad de reportes',
              'Total de horas'
            ];
            this.dataSource.data = data.info_reportes;
            this.dataSourceStock.data = data.info_stock;
            this.dataSourceMaquinas.data = data.info_maquina;
            this.dataSource.data = arr;
            this.dataSourceInfoGeneral.data = data.info_dashboard;
            this.ELEMENT_DATA_BACKUP_INFO_GENERAL = data.info_dashboard;
          } else {
            this.dataSource.data = data.info_reportes;
            this.dataSourceStock.data = data.info_stock;
            this.dataSourceMaquinas.data = data.info_maquina;
            this.dataSourceInfoGeneral.data = data.info_dashboard;
            this.ELEMENT_DATA_BACKUP_INFO_GENERAL = data.info_dashboard;
            this.info_dashAll = data;
          }
          this.filterSelectObj.filter((o) => {
            o.options = this.getFilterObject(data.info_dashboard, o.columnProp);

          });
          this.slimLoadingBarService.complete();
        },
        error => {
          this.slimLoadingBarService.complete();
          this.snackBar.open('Error intentarlo denuevo', '', {
            duration: 3000, verticalPosition: 'bottom',
            panelClass: ['blue-snackbar']
          });
        });


  }

  // tslint:disable-next-line:typedef
  onClickCard_Show_Inspections() {
   // console.log('onClickCard_Show_document');
    this.router.navigate(['inventario']);
  }
  // tslint:disable-next-line:typedef
  onClickCard_Show_Documents() {
   // console.log('onClickCard_Show_document');
    this.router.navigate(['documentos']);
  }
  // tslint:disable-next-line:typedef
  onSelectCard(path: reporte) {
    this.router.navigate(['inspeccion', path._id], { state: { _id: path._id} });
  }
  assertItemType(disCol: string, item: dashboard_reportes): string {
    if (disCol === 'Cantidad de reportes'){
      return item.count_reportes.toString();
    }else if (disCol === 'Operario'){
      return item.nombre_completo;
    }else if (disCol === 'Total horas reportes'){
      return item.total_horas.toString();
    }else if (disCol === 'Rumas/Hr'){
      return item.promedio_metros_arrumados_hora.toString();
    }else if (disCol === 'Arrumado Turno A'){
      return item.total_metros_arrumados_turno_a.toString();
    }else if (disCol === 'Arrumado Turno B'){
      return item.total_metros_arrumados_turno_b.toString();
    }else if (disCol === 'Arrumado Sin Turno'){
      return item.total_metros_arrumados_sin_turno.toString();
    }
  }
  assertItemTypeInfoGeneral(disCol: string, item: dashboard_info): string {
    if (disCol === 'Faena'){
      return item.faena;
    }else if (disCol === 'Nombre'){
      return item.nombre;
    }else if (disCol === 'Apellido'){
      return item.apellido;
    }else if (disCol === 'Cargo'){
      return item.cargo;
    }else if (disCol === 'Total Horas'){
      return item.total_horas.toString();
    }else if (disCol === 'Total Combustible'){
      return item.total_combustible.toString();
    }else if (disCol === 'Total Reportes'){
      return item.total_combustible.toString();
    }
  }



  assertItemTypeMaquina(disCol: string, item: dashboard_maquinas): string {
   // console.log(item);
    if (disCol === 'Nº'){
      return item.id_maquina.toString();
    }else if (disCol === 'Nombre'){
      return item.nombre;
    }else if (disCol === 'Total consumo(Lt)'){
      return item.total_consumo.toString();
    }else if (disCol === 'Promedio(Lt)'){
      return item.promedio.toString();
    }else if (disCol === 'Lt/hora'){
      return item.litros_horas.toString();
    }else if (disCol === 'Número reportes'){
      return item.numero_reportes.toString();
    }else if (disCol === 'Aceite cadena/hr'){
      return  item.aceite_cadena_hr.toString();
    }

  }
  assertItemTypeStock(item: dashboard_stock): dashboard_stock {
   return item;
  }

  // tslint:disable-next-line:typedef
  filterChangeInfoGeneral(filter, event) {
    this.filterInfoGeneralValues[filter.columnProp] = event.target.value.trim().toLowerCase();
    const final = [];
    for (const item of this.ELEMENT_DATA_BACKUP_INFO_GENERAL) {
      const checkData = this.filterDataFunction(item, JSON.stringify(this.filterInfoGeneralValues));
      // console.log(checkData)
      if (checkData) {
        final.push(item);
      }
    }
    //  console.log('final.length');
    //  console.log(final.length);
    if (final.length > 0) {
      // @ts-ignore
      this.dataSourceInfoGeneral.data = final;
    }else{
      this.dataSourceInfoGeneral.data = final;
    }
  }
  public filterDataFunction(data: dashboard_info, filter: string): boolean {
    const searchTerms = JSON.parse(filter);

    let isFilterSet = false;
    for (const col in searchTerms) {
      if (searchTerms[col].toString() !== '') {
        isFilterSet = true;
      } else {
        delete searchTerms[col];
      }
    }

    const nameSearch = () => {
      let found = false;
      let count_filter = 0;

      if (isFilterSet) {

        // tslint:disable-next-line:forin
        for (const col in searchTerms) {
           console.log(col);
           console.log(data);
           if (col === 'faena') {
            if (data.faena != null) {
              // tslint:disable-next-line:triple-equals
              if (data[col].toLowerCase().indexOf(searchTerms[col].toLowerCase()) != -1 && isFilterSet) {
                count_filter = count_filter + 1;
              }
            }
          }else{
            if (!(data[col].toString().toLowerCase().indexOf(searchTerms[col].trim().toLowerCase()) === -1) && isFilterSet) {
              count_filter = count_filter + 1;
            }
          }
        }
        const size = Object.keys(searchTerms).length;
        // console.log(size)
        if (count_filter === 0){

          found =  false;
        }else{
          if (count_filter === size){
            found = true;
          }else{
            found =  false;
          }
        }
        return found;
      } else {
        return true;
      }
    };
    return nameSearch();
  }
  // tslint:disable-next-line:typedef
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  resetFilters() {
    this.clearFilter = true;
    // this.filterValues = {};
    this.ELEMENT_DATA_INFO =  this.ELEMENT_DATA_BACKUP_INFO_GENERAL;
    this.dataSourceInfoGeneral.data = this.ELEMENT_DATA_BACKUP_INFO_GENERAL;
    this.filterSelectObj.forEach((value, key) => {
      value.modelValue = undefined;
    });
    this.snackBar.open('Limpio el Filtro', '', {
      duration: 3000, verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
    setTimeout(() => this.clearFilter = false, 3000);
  }
}
