<!DOCTYPE html>
<html>

<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css">
</head>

<body>


<ng-container *ngIf="isDSSInspection">
  <div fxLayout="row"  fxFlexFill> <!--fxLayoutAlign="center center"!-->
    <div fxFlex="95" fxFlexFill>
      <div fxLayout="row" class="flex-item">
        <mat-grid-list cols="8" rowHeight="40px" fxFlex="100">
          <mat-grid-tile colspan="5" rowspan="2" class="title">
            Albero


          </mat-grid-tile>
          <mat-grid-tile colspan="3" rowspan="2" >
            <img class="tile-image" src="./assets/images/logo_albero_spa_2.png">
          </mat-grid-tile>
          <mat-grid-tile colspan="8" rowspan="2" class="title">Reporte supervisor</mat-grid-tile>
        </mat-grid-list>
      </div>
      <div fxLayout="row" class="flex-item mb-3">
        <div fxFlex="100" >
          <mat-grid-list cols="16" rowHeight="40px" fxFlex="100">



            <mat-grid-tile colspan="16" rowspan="2" class="title">DATOS GENERALES</mat-grid-tile>

            <mat-grid-tile colspan="4" rowspan="1" class="title">Supervisor</mat-grid-tile>
            <mat-grid-tile colspan="4" rowspan="1"  class ="title-normal">{{ _data.supervisor_nombre_completo }}</mat-grid-tile>


            <mat-grid-tile colspan="4" rowspan="1" class="title">Operador</mat-grid-tile>
            <mat-grid-tile colspan="4" rowspan="1" class ="title-normal">{{ _data.operador_nombre_completo }}</mat-grid-tile>

            <mat-grid-tile colspan="4" rowspan="1" class="title">Jefatura</mat-grid-tile>
            <mat-grid-tile colspan="4" rowspan="1" class ="title-normal">{{ _data.jefe_nombre_completo }}</mat-grid-tile>








            <mat-grid-tile colspan="4" rowspan="1" class="title">Fecha</mat-grid-tile>
            <mat-grid-tile colspan="4" rowspan="1" class ="title-normal" >{{ _data.fecha| date :  "MMM d, y h:mm:ss a"}}<!--:  "MMM d, y h:mm:ss a"--></mat-grid-tile>

            <mat-grid-tile colspan="4" rowspan="1" class="title">Mapa</mat-grid-tile>
            <mat-grid-tile colspan="12" rowspan="1">

              <button  mat-raised-button (click)="show_maps_position()">
                <mat-icon class="svg" svgIcon="map-marker-outline"  ></mat-icon>
                Ver mapa</button>
            </mat-grid-tile>



            <mat-grid-tile colspan="16" rowspan="1" class="title">     </mat-grid-tile>



            <ng-container >
              <mat-grid-tile colspan="16" rowspan="2" class="title"> ⛽ Combustible </mat-grid-tile>

              <mat-grid-tile colspan="4" rowspan="1" class="title">Camioneta</mat-grid-tile>
              <mat-grid-tile colspan="4" rowspan="1" class ="title-normal">{{ _data.camioneta_nombre }}</mat-grid-tile>


              <mat-grid-tile colspan="4" rowspan="1" class="title">Litros Combustible</mat-grid-tile>
              <mat-grid-tile colspan="4" rowspan="1" class ="title-normal">{{ _data.cantidad_combustible }}</mat-grid-tile>

            </ng-container>



            <ng-container >
              <mat-grid-tile colspan="16" rowspan="2" class="title"> 🛠   Producción Rumas </mat-grid-tile>

              <mat-grid-tile colspan="4" rowspan="1" class="title">Turno</mat-grid-tile>
              <mat-grid-tile colspan="4" rowspan="1" class ="title-normal">{{ _data.turno_nombre }}</mat-grid-tile>


              <mat-grid-tile colspan="4" rowspan="1" class="title">Metros Arrumados</mat-grid-tile>
              <mat-grid-tile colspan="4" rowspan="1" class ="title-normal">{{ _data.metros_arrumados }}</mat-grid-tile>


              <mat-grid-tile colspan="4" rowspan="1" class="title">Observaciones mecánicas</mat-grid-tile>
              <mat-grid-tile colspan="12" rowspan="1" class ="title-normal">{{ _data.observaciones_mecanicas }}</mat-grid-tile>


            </ng-container>





            <ng-container *ngIf="_data.info_fotos.length >0 && _data.info_fotos[0]._id !== '-1' ; else notShow">
              <mat-grid-tile colspan="16" rowspan="2" class="title">📷 Imágenes</mat-grid-tile>

              <mat-grid-tile colspan="8" rowspan="1" class="title">Fecha</mat-grid-tile>
              <mat-grid-tile colspan="8" rowspan="1" class ="title-normal" >Mostrar</mat-grid-tile>

              <ng-container *ngFor="let file of _data.info_fotos">
                <mat-grid-tile colspan="8" rowspan="1" class ="title-normal" >{{ assertItemType(file).fecha | date :  "MMM d, y h:mm:ss a"}}</mat-grid-tile>

                <mat-grid-tile colspan="8" rowspan="1" ><button mat-raised-button (click)="show_image(assertItemType(file))">Mostrar</button></mat-grid-tile>
                <!--  <mat-grid-tile colspan="4" rowspan="6">
                    <img src='{{ file.url }}' class="tile-image">
                  </mat-grid-tile>-->
              </ng-container>
            </ng-container>





            <ng-template #notShow>

              <mat-grid-tile colspan="16" rowspan="2" class="title">
                <mat-icon svgIcon="image-off-outline"></mat-icon>
                Sin imágenes 📷
              </mat-grid-tile>

            </ng-template>


            <ng-template #Loading>

              <mat-grid-tile colspan="16" rowspan="2" class="title">
                <mat-icon svgIcon="image-off-outline"></mat-icon>
                Sin imágenes
              </mat-grid-tile>

            </ng-template>
          </mat-grid-list>
        </div>
      </div>
    </div>
  </div>
</ng-container>






<!-- Placed at the end of the document so the pages load faster -->
<script src="//ajax.googleapis.com/ajax/libs/jquery/1.12.0/jquery.min.js"></script>
<script src="//maps.googleapis.com/maps/api/js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/js/bootstrap.min.js"></script>

</body>

</html>
