import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import {environment} from '../../environments/environment.prod';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../_models/User';
import {map} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';
import {Login} from '../_models/login';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public TOKENN = '';
  constructor(private permissionsService: NgxPermissionsService, private http: HttpClient, private userService: UserService) {

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  login(email, password) {
    const  ciphertext = CryptoJS.AES.encrypt(password, environment.tokenPassword).toString();
    return this.http.post<Login>(`${environment.apiUrl}/login/`, { email, ciphertext})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
       // console.log(user);
       // console.log(user.info_user.permission);
        if (user) {
          const perm = [user.info_user.permission];
          this.permissionsService.loadPermissions(perm);


          // && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user.info_user));
          this.currentUserSubject.next(user.info_user);
          return user;
        }
        return user;
      }));
  }

  logout() {
   // console.log('Logout');
  //  this.afAuth.auth.signOut();
    localStorage.removeItem('currentUser');
    this.permissionsService.flushPermissions();
  }

  register(cargo, fname, lname, email, password, company, permission, type) {
    const TOKENN = 'cAV2phzBxnD6LSgr';
    const  ciphertext = CryptoJS.AES.encrypt(password, TOKENN).toString();
   // console.log('ciphertext');
  //  console.log(ciphertext);
    return this.http.post<Login>(`${environment.apiUrl}/register_user/`, {cargo,fname, lname, email, password , ciphertext, company, permission, type})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
       // console.log(user);
      //  console.log(user.info_user.permission);
        if (user.success) {
         // const perm = [user.info_user.permission];
         // this.permissionsService.loadPermissions(perm);
          // && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
      //    localStorage.setItem('currentUser', JSON.stringify(user.info_user));
        //  this.currentUserSubject.next(user.info_user);
          return user;
        }
        return user;
      }));
  }

  // tslint:disable-next-line:variable-name
  Edit(id_stock, nombre, cantidad, status) {
    if (this.userService.get_StockEdit().value._id !== 0) {
     // const status = 'add';


      console.log(status)

      return this.http.post<Login>(`${environment.apiUrl}/edit_stock/`, {id_stock, nombre, cantidad, status})
        .pipe(map(user => {
          // login successful if there's a jwt token in the response
         // console.log(user);
          //  console.log(user.info_user.permission);
          if (user.success) {
            // const perm = [user.info_user.permission];
            // this.permissionsService.loadPermissions(perm);
            // && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            //    localStorage.setItem('currentUser', JSON.stringify(user.info_user));
            //  this.currentUserSubject.next(user.info_user);
            return user;
          }
          return user;
        }));
    }else{
      //const status = 'update';
      return this.http.post<Login>(`${environment.apiUrl}/edit_stock/`, {id_stock, nombre, cantidad, status})
        .pipe(map(user => {
          // login successful if there's a jwt token in the response
         // console.log(user);
          //  console.log(user.info_user.permission);
          if (user.success) {
            // const perm = [user.info_user.permission];
            // this.permissionsService.loadPermissions(perm);
            // && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            //    localStorage.setItem('currentUser', JSON.stringify(user.info_user));
            //  this.currentUserSubject.next(user.info_user);
            return user;
          }
          return user;
        }));
    }

  }
}
