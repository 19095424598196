 <mat-list>
   <mat-list-item>
     <div fxFlex="100" fxLayout="row">
       <div class="in-charge mt-1" fxFlex="80">
         <h4>Menu</h4>
       </div>
       <div class="in-charge mt-1" fxFlex="20">
         <mat-icon  class="svg" mat-list-icon svgIcon="close" (click)="closeMenu()"> </mat-icon>
       </div>
     </div>

   </mat-list-item>
    <div *ngFor="let section of menuGuestCadetechMobile">
      <mat-list>
        <div *ngFor="let subSection of section?.children">
          <mat-list-item>
            <mat-icon  class="svg" mat-list-icon [svgIcon]=subSection.icon ></mat-icon>
            <button mat-line mat-button (click)="onNavigateTo(subSection.link,$event)">
              {{ subSection.label }}
              <span *ngIf="subSection.beta" class="beta-option">beta</span>
            </button>

          </mat-list-item>
        </div>

      </mat-list>
    </div>
  </mat-list>
