import {Component, Input, OnInit} from '@angular/core';
import { infoMachine } from '../_models/infoMachine';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tooltip-component',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  static hide() {
      throw new Error("Method not implemented.");
  }

  @Input() info: infoMachine;

  constructor() {}

  ngOnInit(): void {
    //console.log("aqui");
  }

  // tslint:disable-next-line:typedef
  /* https://stackblitz.com/edit/angular-s7zevt?file=app%2Ftool-tip-renderer.directive.ts*/
  /*ejemplo que utilice para generar la instancia y pasar la info*/
  public setData(info: infoMachine){
  }
}
