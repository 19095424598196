import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuMobileComponent } from '../_bottomSheet/menu-mobile/menu-mobile.component';
import { MenuGroup } from '../_models/menu';
import { AuthenticationService } from '../_services/authentication.service';
import { SidenavService } from '../_services/sidenav.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  constructor(
    // tslint:disable-next-line:variable-name
    @Inject(DOCUMENT) private _document: Document,
    private permissionsService: NgxPermissionsService,
    private storage: StorageMap,
    private userService: UserService,
    private authService: AuthenticationService,
    private router: Router,
    private sidenavService: SidenavService,
    // tslint:disable-next-line:variable-name
    private _bottomSheet: MatBottomSheet,
    private deviceService: DeviceDetectorService
  ) {}

  public isSidenavOpen = false;
  public isMobileMenu = false;
  deviceInfo = null;

  public menuUserSelected: MenuGroup[];
  public menuGuestMaster: MenuGroup[] = [
    {


      groupName: 'Menú',
      permissions: ['albero-machines'],
      children: [
        {
          label: 'Reportes',
          icon: 'file-document-outline',
          link: 'reportes',
          permissions: ['albero-machines'],
        },
        {
          label: 'Inventario',
          icon: 'format-list-checks',
          link: 'inventario',
          permissions: ['albero-machines'],
        },
        { label: 'Lista usuarios',
          icon: 'account-multiple-outline',
          link: 'usuarios',
          permissions: ['albero-machines']
        }
      ],
    },
  ];


  public menuGuestOperator: MenuGroup[] = [
    {


      groupName: 'Menú',
      permissions: ['cadetech-machines'],
      children: [
        {
          label: 'Reportes',
          icon: 'file-document-outline',
          link: 'reportes',
          permissions: ['cadetech-machines'],
        },
        {
          label: 'Inventario',
          icon: 'format-list-checks',
          link: 'inventario',
          permissions: ['cadetech-machines'],
        }
      ],
    },
  ];

  @ViewChild('sidenav', { static: false })
  drawer: MatSidenav;

  ngOnInit(): void {
    this.sidenavService.getStatus().subscribe((status) => {
      this.isSidenavOpen = status;
    });
    this.storage.delete('list_machines').subscribe(() => {});
   /** if (this.authService.currentUserValue) {
      const permission = this.authService.currentUserValue.permission;
    }**/

    const permission = this.authService.currentUserValue.permission;
   // console.log(permission)
    if (permission === 'OPERADOR') {
      this.menuUserSelected = this.menuGuestOperator;
    } else if ('ADMIN') {
      this.menuUserSelected = this.menuGuestMaster;
    }

   // this.menuUserSelected = this.menuGuestMaster;

    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (isMobile) {
      this.isMobileMenu = true;
    } else if (isDesktopDevice) {
      this.isMobileMenu = false;
    } else if (isTablet) {
      this.isMobileMenu = true;
    }
  }

  public editInformation(): void {
   // console.log('edit_information');
    const user = this.authService.currentUserValue;
    this.userService.User_Edit(user);
    this.router.navigate(['registrar'], { state: { register: 'UPDATE' } });
  }

  public closeSession(): void {
    this.storage.clear().subscribe(() => {});
    this.authService.logout();
    this.router.navigate(['/login']);
    this._document.defaultView.location.reload();
  }

  public onToggleSidenav(): void {
    if (this.isMobileMenu) {
      this._bottomSheet.open(MenuMobileComponent);
    } else {
      if (this.isSidenavOpen) {
        this.drawer.open();
      } else {
        this.drawer.close();
      }
      this.isSidenavOpen = !this.isSidenavOpen;
      this.sidenavService.toggleSidenav(this.isSidenavOpen);
    }
  }
  public onNavigateTo(link: string): void {
    this.router.navigate([link]);
  }
}
