import {Component, OnInit, ViewChild} from '@angular/core';
import {SlimLoadingBarService} from 'ng2-al-slim-bar';
import {ManagmentUsersService} from '../_services/managment-users.service';
import {User} from '../_models/User';
import {ConfirmDialogComponent} from '../_dialogs/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {UserService} from '../_services/user.service';
import {Role} from '../_models/role';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css']
})
export class ListUsersComponent implements OnInit {
  @ViewChild('dataTable') dataTable: MatTable<any>;
  dataSource: MatTableDataSource<User> ;
  displayedColumns: string[] = ['name', 'firstname', 'email', 'cargo', 'company', 'permission', 'actions'];
  // tslint:disable-next-line:max-line-length
  constructor(private userService: UserService, private router: Router, private dialog: MatDialog, private slimLoadingBarService: SlimLoadingBarService, private  managmentUsersService: ManagmentUsersService) { }
  ELEMENT_DATA: User[] = [];
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<User>(this.ELEMENT_DATA);
    this.loadData();

    // tslint:disable-next-line:variable-name
  /**  const  example_user: User = {
   id: 1,
   _id: '1',
   name: 'Daniel',
   last_name: 'Verdugo',
   username: 'dverdugo',
   password: '1',
   firstname: 'Daniel',
   lastname: 'Verdugo',
   company: 'Albero',
   permission: 'admin',
   email: 'dverdugo85@gmail.com',
 };**/

  //  this.ELEMENT_DATA.push(example_user);

  }
  public loadData() {

    this.slimLoadingBarService.start(() => {
     // console.log('Loading complete');
    });

    this.managmentUsersService.get_all_users().subscribe(
      (data) => {
        this.ELEMENT_DATA = data;

        this.dataSource.data = this.ELEMENT_DATA;
        this.slimLoadingBarService.complete();
      },
      (error) => { console.log('error', error); },
      () => {
        console.log('Finally'); }
    );
  }
  onSelectUser( path: User) {
    this.userService.User_Edit(path);
    this.router.navigate(['registrar'], { state: { register: 'UPDATE' } });
  //  window.open( 'https://drive.google.com/file/d/' + path.id + '/view');
  }
  onSelect_Create_User() {
    this.userService.clear_User_Edit() ;
    this.router.navigate(['registrar'], { state: { register: 'REGISTER' }});
  }
  onDelete( path: User) {
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmar',
        body: '¿Está seguro de eliminar a ' + path.name + ' ?',
        confirm: 'Borrar',
        confirmClass: 'danger-button',
        cancel: 'Cancelar'
      }
    });
    confirmRef.afterClosed().subscribe(result => {
     if (result) {
      // console.log(path._id)
      this.managmentUsersService.delete_user(path._id).subscribe(
          data => {
            this.deleteRowData(path);
           // this.router.navigate(['inspecciones']);
          }
        );
      }
    });
  }
  deleteRowData(rowobj: User) {
    const index: number = this.ELEMENT_DATA.findIndex(d => d === rowobj);
    this.ELEMENT_DATA.splice(index, 1);
    this.dataSource = new MatTableDataSource<User>(this.ELEMENT_DATA);
  }
}
