import { Component, OnInit } from '@angular/core';
import {reporte} from './models/reporte';
import {MatDialog} from '@angular/material/dialog';
import {AgmDialogComponent} from '../_dialogs/agm-dialog/agm-dialog.component';
import * as url from 'url';
import {ImgDialogComponent} from '../_dialogs/img-dialog/img-dialog.component';
import {environment} from '../../environments/environment';
import {imagen} from './models/imagen';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SlimLoadingBarService} from 'ng2-al-slim-bar';
import {ManagmentFilesService} from '../_services/managment-files.service';
import {itemStock} from './models/itemStock';

@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.css']
})
export class ReporteComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public _data: reporte;

  public isDSSInspection = false;
  constructor( private dialog: MatDialog,
               private slimLoadingBarService: SlimLoadingBarService,
               private snackBar: MatSnackBar,
               private http: HttpClient,
               private router: Router,
               private activatedRouter: ActivatedRoute,
               private managmentFilesService: ManagmentFilesService) {


  }

  ngOnInit(): void {
// @ts-ignore




    this.activatedRouter.params.subscribe(params => {

      this.slimLoadingBarService.start(() => {

      });
      this.managmentFilesService.get_info_reporte_resumen_info_tabla(params.id).subscribe(
        data => {
          this.slimLoadingBarService.complete();
          if (!data) {

            this.snackBar.open('Inspección sin información', '', {
              duration: 3000, verticalPosition: 'bottom',
              panelClass: ['blue-snackbar']
            });

            this.router.navigate(['inspecciones']);
          }
          console.log(data.rows[0])
          this.isDSSInspection = true;
          this._data = data.rows[0];
        });
    });



  }


  // tslint:disable-next-line:typedef
  show_maps_position() {
    // tslint:disable-next-line:variable-name
    const  lat_ = this._data.lat;//'-37.4629159';
    // tslint:disable-next-line:variable-name
    const lng_ = this._data.lon;//'-72.3612251';

    if(lat_ === 0 && lng_ === 0 ){
      this.snackBar.open('Sin coordenadas', '', {
        duration: 3000, verticalPosition: 'bottom',
        panelClass: ['blue-snackbar']
      });
    }else{
      const confirmRef = this.dialog.open(AgmDialogComponent, {
        data: {
          lat :  Number(lat_),
          lng : Number(lng_),
          zoom: 12,
          confirm: 'Borrar',
          confirmClass: 'danger-button',
          cancel: 'Cerrar'
        }
      });

      confirmRef.afterClosed().subscribe(result => {
        if (result) {

        }
      });
    }


  }

  // tslint:disable-next-line:variable-name
  show_image(urlshow: imagen) {

    const confirmRef = this.dialog.open(ImgDialogComponent, {
      data: {
        lng: 26,
        lat: 26,
        url: urlshow.url,
        confirm: 'Descargar',
        confirmClass: 'danger-button',
        cancel: 'Cerrar'
      }
    });

    confirmRef.afterClosed().subscribe(result => {
      if (result) {
       // window.open(`${environment.apiUrl}/download_image_one_inspection/` + id_gdrive + '/download' );
      }
    });
  }



  assertItemType(item: imagen): imagen {
    return item;
  }
  assertItemTypeStock(item: itemStock): itemStock {
    return item;
  }
}
