import { Component, OnInit } from '@angular/core';
import {TooltipComponent} from '../_tooltip/tooltip.component';

@Component({
  selector: 'app-machine',
  templateUrl: './machine.component.html',
  styleUrls: ['./machine.component.css']
})
export class MachineComponent implements OnInit {
  tooltipComponent = TooltipComponent;
  constructor() { }

  ngOnInit(): void {
  }

}
