<div class="component">
  <div class="header">
    <img class="user-icon" src="../assets/images/logo-cadetech.png" />
    <h2>{{info.title}}</h2>
  </div>
  <div class="body">
    <p class="center-text">Información máquina</p>
  </div>
  <!-- <mat-divider class="footer-divider"></mat-divider>
  <div class="footer">
    <small class="right-text">Footer del componente</small>
  </div>-->
</div>
