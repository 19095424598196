import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { TooltipComponent } from '../_tooltip/tooltip.component';
import {infoMachine} from '../_models/infoMachine';



@Component({
  selector: 'app-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.css'],
})
export class MachinesComponent implements OnInit {
  @ViewChild('tooltip') tooltip: ElementRef;

  tooltipComponent = TooltipComponent;
  info: infoMachine;
  info2: infoMachine;

  public ElementDataMachine: infoMachine[] = [

    {
      title: 'Rotola roja prueba',
      photoLink: 'https://sfxpublic.s3-sa-east-1.amazonaws.com/test_webviewer/0201_Rotopala_rojo.png',
      ImagePositionX: '0',
      ImagePositionY: '0',
      ObPositionX: '1150',
      ObPositionY: '100',
      ObHeight: '80',
      ObWidth: '80'
    }];
  constructor(private renderer: Renderer2, private router: Router) {}

  ngOnInit(): void {
/// lo idea es tener un array simulando que viene del servidor


    // @ts-ignore
  this.info =  {
    title: 'Rotola',
    photoLink: 'https://sfxpublic.s3-sa-east-1.amazonaws.com/test_webviewer/0201_Rotopala_rojo.png',
    ImagePositionX: '0',
    ImagePositionY: '0',
    ObPositionX: '1150',
    ObPositionY: '100',
    ObHeight: '80',
    ObWidth: '80'
  };

  this.info2 =  {
  title: 'Rotola roja final',
  photoLink: 'https://sfxpublic.s3-sa-east-1.amazonaws.com/test_webviewer/0201_Rotopala_rojo.png',
  ImagePositionX: '0',
  ImagePositionY: '0',
  ObPositionX: '1150',
  ObPositionY: '100',
  ObHeight: '80',
  ObWidth: '80'
};

  }

  onOpenTooltip($event: MouseEvent): void {
    const circle = $event.target as HTMLElement;
    const coordinates = circle.getBoundingClientRect();
   // console.log(coordinates.left);
   // console.log(coordinates.top);
    const x = `${coordinates.left + 20}px`;
    const y = `${coordinates.top + 20}px`;
    // this.renderer.setStyle(this.tooltip.nativeElement, 'left', x);
    // this.renderer.setStyle(this.tooltip.nativeElement, 'top', y);
    // this.renderer.setStyle(this.tooltip.nativeElement, 'display', 'block');
    // this.renderer.setProperty(this.tooltip.nativeElement, 'innerHTML', data);
  }

  onCloseTooltip($event: MouseEvent): void {
    // this.renderer.setProperty(this.tooltip.nativeElement, 'innerHTML', '');
    // this.renderer.setStyle(this.tooltip.nativeElement, 'display', 'none');
  }

  onMachineSelected(ElementDataMachine: infoMachine): void {
   // (this.tooltipComponent as Any).hide();
  //  console.log(ElementDataMachine);
  //  this.router.navigate(['/maquina']);
  }

  public mouseEnter($event, data): void {
    const circle = $event.target as HTMLElement;
    const coordinates = circle.getBoundingClientRect();
    const x = `${coordinates.left + 200}px`;
    const y = `${coordinates.top + 200}px`;
    this.renderer.setStyle(this.tooltip.nativeElement, 'left', x);
    this.renderer.setStyle(this.tooltip.nativeElement, 'top', y);
    this.renderer.setStyle(this.tooltip.nativeElement, 'display', 'block');
    this.renderer.setProperty(this.tooltip.nativeElement, 'innerHTML', data);
  }

  public mouseLeave($event): void {
    this.renderer.setProperty(this.tooltip.nativeElement, 'innerHTML', '');
    this.renderer.setStyle(this.tooltip.nativeElement, 'display', 'none');
  }



}
