import { Role } from './role';

export class User {
  constructor(name: string) {
    this.name = name;
  }

  id: number;
  _id: string;
  name: string;
  last_name:string;
  username: string;
  password: string;
  firstname: string;
  lastname: string;
  role?: Role;
  token?: string;
  company: string;
  permission: string;
  email: string;
  cargo: string;
}
