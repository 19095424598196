
<h1 mat-dialog-title>Ubicación</h1>
<div mat-dialog-content>
  <div>
    <button  class ="mt-1 mb-2" mat-button mat-raised-button (click)="changeMap()">
      Cambiar mapa
    </button>
  </div>
  <agm-map id="capture-work-map" [latitude]="data.lat" [longitude]="data.lng" [zoom]="data.zoom"  [mapTypeId]="typemap">
  <agm-marker [markerDraggable]="true" [latitude]="data.lat" [longitude]="data.lng"></agm-marker>
    </agm-map>
</div>


<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ data.cancel }}</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <!-- <button mat-button [mat-dialog-close]="true" class="{{ data.confirmClass }}">{{ data.confirm }}</button>-->
</mat-dialog-actions>




